import React from "react";
import logoImage from "../img/logo-preview.png";
import { Link } from "react-router-dom";

function Logo({ ForMobile }) {
  console.log(ForMobile, "***** mobile");
  return (
    <>
      {ForMobile ? (
        <Link to="" className="navbar-brand d-block d-lg-none">
          <img
            src={logoImage}
            alt=""
            className="img-fluid"
            style={{ width: "36%", float: "right" }}
          />
        </Link>
      ) : (
       <Link to="/">
         <img
          src={logoImage}
          alt=""
          className="img-fluid"
          style={{ width: "100%"}}
        />
       </Link>
      )}
    </>
  );
}

export default Logo;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APIEndtPoint } from "./Apis";
import WhatsAppImage from "../img/whatsapp-144.png";
import imageUrl from "../img/phone-call-1.png";

function PlanBox() {
  const [data, setData] = useState(null);
  const [allCustomizeCategoriesData, setAllConstomizeCategoriesData] =
    useState(null);
  const [allCorporateCategoriesData, setCorporateCategoriesData] =
    useState(null);
  const [fullHouseServices, setFullHouseServices] = useState(null);
  const [selectedBHK, setSelectedBHK] = useState("1");
  const [selectedCustomizeCategory, setSelectedCostomizeCategory] =
    useState("4");
  const [selectedCorporateCategory, setSelectedCorporateCategory] =
    useState("13");

  const navigate = useNavigate();

  const handleSelectChange = (event) => {
    setSelectedBHK(event.target.value);
  };

  const handleSelectCategoryChange = (event) => {
    setSelectedCostomizeCategory(event.target.value);
  };

  const handleSelectCorporateCategoryChange = (event) => {
    setSelectedCorporateCategory(event.target.value);
  };

  const handleBookNow = () => {
    navigate(`/buynow?category_id=${selectedBHK}`);
  };

  const handleBookNowCustomizeCategory = () => {
    navigate(`/buynow?category_id=${selectedCustomizeCategory}`);
  };

  const handleBookNowCorporateCategory = () => {
    navigate(`/buynow?category_id=${selectedCorporateCategory}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(APIEndtPoint + "categories/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ category_type: "monthly_package" }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Response:", result);
        setData(result);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    };
    const fetchAllCategoriesData = async () => {
      const response = await fetch(APIEndtPoint + "categories/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ category_type: "customizable_package" }),
      });
      if (response.ok) {
        const result = await response.json();
        setAllConstomizeCategoriesData(result);
      }
    };
    const fetchCorporateCategoriesData = async () => {
      const response = await fetch(APIEndtPoint + "categories/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ category_type: "corporate_services" }),
      });
      if (response.ok) {
        const result = await response.json();
        setCorporateCategoriesData(result);
      }
    };
    const fetchFullHouseServices = async () => {
      const response = await fetch(APIEndtPoint + "categories/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ category_type: "full_house_services" }),
      });
      if (response.ok) {
        const result = await response.json();
        setFullHouseServices(result[0]);
      }
    };

    fetchData();
    fetchAllCategoriesData();
    fetchCorporateCategoriesData();
    fetchFullHouseServices();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="plan-box">
            <div className="">
              <div className="">
                <h1 className="mb-3"></h1>
                <div className="dv-booking-holder">
                  <div className="dv-booking">
                    <div className="dv-icon">
                      <img src={imageUrl} style={{ width: "20px" }} />
                    </div>
                    <div
                      className="dv-booking-content"
                      style={{ display: "none;" }}
                    >
                      <a href="tel:+91-8006305606" className="text-white">
                        Call Us
                      </a>
                    </div>
                  </div>
                  <div className="dv-offer">
                    <div className="dv-icon">
                      <img src={WhatsAppImage} style={{ width: "20px" }} />
                    </div>
                    <div
                      className="dv-booking-whatup"
                      style={{ display: "none;" }}
                    >
                      <a
                        href="https://wa.me/918006305606"
                        target="_blank"
                        className="text-white"
                      >
                        Chat Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-3"></div>
                <div className="col-md-6 col-sm-6">
                  <div className="ribbon title">Select Your Package</div>
                </div>
                <div className="col-md-3 col-sm-3"></div>
              </div>
              <div className="row p-3"></div>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  {/* <div className="row"> */}
                  <div className="pricingTable">
                    <div className="pricingTable-header">
                      <h3 className="title">Monthly Package</h3>
                      <div className="col-lg-12">
                        <div className="text-center">
                          <div className="col-sm-12 control-group">
                            <select
                              name="select-bhks"
                              id="select-bhks"
                              className="form-control"
                              onChange={handleSelectChange}
                            >
                              {data &&
                                data.map((category) => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pricing-content">
                      <div className="content">
                        {/* 2BHK Package */}

                        {data &&
                          data
                            .filter(
                              (category) =>
                                category.id.toString() === selectedBHK
                            )
                            .map((category) => (
                              <div
                                key={category.id}
                                dangerouslySetInnerHTML={{
                                  __html: category.description,
                                }}
                              />
                            ))}
                      </div>
                      <div className="d-flex justify-content-center">
                        <a onClick={handleBookNow} className="btn btn-info mb-3">
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div className="col-lg-4 col-md-6">
                  {/* <div className="row"> */}
                  <div className="pricingTable">
                    <div className="pricingTable-header">
                      <h3 className="title">Customize Service</h3>
                      <div className="col-lg-12">
                        <div className="text-center">
                          <div className="col-sm-12 control-group">
                            <select
                              name="select-bhks"
                              id="select-bhks"
                              className="form-control"
                              onChange={handleSelectCategoryChange}
                            >
                              <option key='' value='4'>
                                Full House Cleaning
                              </option>
                              {allCustomizeCategoriesData &&
                                allCustomizeCategoriesData.map((category) => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pricing-content">
                      <div className="content">
                        {selectedCustomizeCategory != '4' ?
                          (allCustomizeCategoriesData &&
                            allCustomizeCategoriesData
                              .filter(
                                (category) =>
                                  category.id.toString() ===
                                  selectedCustomizeCategory
                              )
                              .map((category) => (
                                <div key={category.id}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: category.description,
                                    }}
                                  />
                                </div>
                              ))
                          ) : (
                            fullHouseServices && (
                              <div key={fullHouseServices.id}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: fullHouseServices.description,
                                  }}
                                />
                              </div>
                            )
                          )
                        }
                      </div>
                      <div className="d-flex justify-content-center">
                        <a
                          onClick={handleBookNowCustomizeCategory}
                          className="btn btn-info mb-3"
                        >
                          Book Now
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div className="col-lg-4 col-md-6">
                  {/* <div className="row"> */}
                  <div className="pricingTable">
                    <div className="pricingTable-header">
                      <h3 className="title">Corporate Service</h3>
                      <div className="col-lg-12">
                        <div className="text-center">
                          <div className="col-sm-12 control-group">
                            <select
                              name="select-bhks"
                              id="select-bhks"
                              className="form-control"
                              onChange={handleSelectCorporateCategoryChange}
                            >
                              {allCorporateCategoriesData &&
                                allCorporateCategoriesData.map((category) => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pricing-content">
                      <div className="content">
                        {allCorporateCategoriesData &&
                          allCorporateCategoriesData
                            .filter(
                              (category) =>
                                category.id.toString() ===
                                selectedCorporateCategory
                            )
                            .map((category) => (
                              <div key={category.id}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: category.description,
                                  }}
                                />
                              </div>
                            ))}
                      </div>
                      <div className="d-flex justify-content-center">
                        <Link
                          to='/contact'
                          className="btn btn-info mb-3"
                        >
                          Book Now
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlanBox;

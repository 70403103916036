import React, { useState, useEffect } from "react";
import { APIEndtPoint } from "./Apis";

const AccountComponent = () => {
  const [accountDetails, setAccountDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Track editing mode
  const [loadingAccount, setLoadingAccount] = useState(true);
  const [errorAccount, setErrorAccount] = useState(null);

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        setLoadingAccount(true);

        const token = localStorage.getItem("userToken");
        if (!token) {
          throw new Error("User token not found. Please log in again.");
        }

        const response = await fetch(APIEndtPoint + "v1/profiles/get_profile", {
          headers: {
            Authorization: token,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch account details.");
        }
        const data = await response.json();
        setAccountDetails(data || {});
      } catch (error) {
        setErrorAccount(error.message);
      } finally {
        setLoadingAccount(false);
      }
    };

    fetchAccountDetails();
  }, []);

  const handleAccountChange = (e) => {
    const { name, value } = e.target;
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      data: {
        ...prevDetails.data,
        attributes: {
          ...prevDetails.data.attributes,
          [name]: value,
        },
      },
    }));
  };

  const handleAccountSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("userToken");
      if (!token) {
        throw new Error("User token not found. Please log in again.");
      }

      const requestBody = {
        profile: {
          first_name: accountDetails.data.attributes.first_name,
          last_name: accountDetails.data.attributes.last_name,
          actual_address: accountDetails.data.attributes.actual_address,
          locality: accountDetails.data.attributes.locality,
        },
      };

      const response = await fetch(
        APIEndtPoint + "v1/profiles/update_profile",
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update account details.");
      }

      const updatedResponse = await fetch(
        APIEndtPoint + "v1/profiles/get_profile",
        {
          headers: { Authorization: token },
        }
      );

      if (!updatedResponse.ok) {
        throw new Error("Failed to fetch updated account details.");
      }

      const updatedData = await updatedResponse.json();
      setAccountDetails(updatedData);
      setIsEditing(false);

      alert("Account details updated successfully!");
    } catch (error) {
      console.error("Error updating account details:", error);
      alert("Error updating account. Please try again later.");
    }
  };

  if (loadingAccount) {
    return <p>Loading account details...</p>;
  }

  if (errorAccount) {
    return <p>{errorAccount}</p>;
  }

  return (
    <div className="tab-content">
      <h3>Account</h3>
      {isEditing ? (
        <form onSubmit={handleAccountSubmit}>
          <div className="form-group">
            <label htmlFor="first_name">First Name</label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={
                accountDetails && accountDetails.data.attributes.first_name
              }
              onChange={handleAccountChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name</label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={accountDetails && accountDetails.data.attributes.last_name}
              onChange={handleAccountChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="actual_address">Address</label>
            <input
              type="text"
              id="actual_address"
              name="actual_address"
              value={
                accountDetails && accountDetails.data.attributes.actual_address
              }
              onChange={handleAccountChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="locality">Locality</label>
            <input
              type="text"
              id="locality"
              name="locality"
              value={accountDetails && accountDetails.data.attributes.locality}
              onChange={handleAccountChange}
            />
          </div>
          <button type="submit">Save Changes</button>
        </form>
      ) : (
        <div className="account-details">
          <p>
            <strong>Full Name:</strong>{" "}
            {accountDetails && accountDetails.data.attributes.full_name}
          </p>
          <p>
            <strong>Actual Address:</strong>{" "}
            {accountDetails && accountDetails.data.attributes.actual_address}
          </p>
          <p>
            <strong>Locality:</strong>{" "}
            {accountDetails && accountDetails.data.attributes.locality}
          </p>
          <p>
            <strong>Email:</strong>{" "}
            {accountDetails && accountDetails.data.attributes.email}
          </p>
          <p>
            <strong>Phone:</strong>{" "}
            {accountDetails && accountDetails.data.attributes.phone_number}
          </p>
          <button onClick={() => setIsEditing(true)}>Edit</button>
        </div>
      )}
    </div>
  );
};
export default AccountComponent;
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <div style={{ backgroundColor: "white" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <style>
                {`
                  li {
                    color: black;
                  }
                `}
              </style>
              <h1 style={{ color: "black" }}>Privacy Policy</h1>
              <p>
                <strong>Effective Date: 20/09/2024</strong>
              </p>

              <p>
                Safaixyz ("we", "our", "us") operates the Safaixyz mobile
                application and website (
                <a href="https://www.safaixyz.com">www.safaixyz.com</a>), which
                provides cleaning services to users. We are committed to
                protecting your personal data and ensuring transparency about
                how we collect, use, and safeguard your information. This
                Privacy Policy applies to all users of our mobile app and
                services.
              </p>

              <h2>1. Information We Collect</h2>
              <p>
                We collect the following types of information when you use our
                app and services:
              </p>
              <ol>
                <li>
                  <strong>Personal Information:</strong>
                  <ul>
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>
                      Home or service area address (entered manually by the
                      user)
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Usage Data:</strong>
                  <ul>
                    <li>Date and time of booking</li>
                    <li>
                      Device Token - ( devise token for notification )
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Service Area Data:</strong>
                  <p>
                    We do not collect location data from your device. Instead,
                    users manually select their service area from a list of
                    available options (e.g., Noida, Delhi, Greater Noida,
                    Gurugram, and more area in the future). Your selected
                    area is only used to display available services in that
                    area.
                  </p>
                </li>
              </ol>

              <h2>2. How We Use Your Information</h2>
              <p>We use the information we collect to:</p>
              <ol>
                <li>Provide and improve our services</li>
                <li>Facilitate bookings of cleaning services</li>
                <li>Communicate with you via email or phone</li>
                {/* <li>
                  Personalize your user experience by showing services based on
                  your selected area
                </li>
                <li>Process transactions and service requests</li> */}
                <li>Comply with legal obligations</li>
              </ol>

              <h2>3. Sharing Your Information</h2>
              <p>
                We do not sell or rent your personal information to third
                parties. However, we may share your information with:
              </p>
              <ol>
                <li>
                  <strong>Service Providers:</strong> Cleaning professionals and
                  third-party service providers who need the information to
                  provide services booked through our app.
                </li>
                <li>
                  <strong>Legal Requirements:</strong> If required by law, to
                  comply with legal obligations, or protect our rights.
                </li>
              </ol>

              <h2>4. Data Security</h2>
              <p>
                We take reasonable measures to protect your personal data from
                unauthorized access, alteration, or disclosure.
                 {/* However, no
                internet or mobile app transmission is entirely secure. While we
                strive to use commercially acceptable means to protect your
                information, we cannot guarantee its absolute security. */}
              </p>

              <h2>5. Your Rights</h2>
              <p>You have the right to:</p>
              <ol>
                <li>
                  Access and review the personal information we hold about you
                </li>
                <li>Request corrections or updates to your personal data</li>
                <li>
                  Request deletion of your personal data, subject to certain
                  conditions
                </li>
                <li>Withdraw consent for data processing where applicable</li>
              </ol>
              <p>
                To exercise any of these rights, please contact us at{" "}
                <a href="mailto:helpdesk@safaixyz.com">helpdesk@safaixyz.com</a>
                .
              </p>

              <h2>6. Cookies and Tracking Technologies</h2>
              <p>
                We may use cookies and similar technologies to collect
                information about your browsing activities on our website and
                app. You can set your browser to refuse cookies, but this may
                affect the functionality of the website or app.
              </p>

              <h2>7. Changes to this Privacy Policy</h2>
              <p>
                We may update our Privacy Policy from time to time. Any changes
                will be posted on this page, and the 09/12/2024 at the top of
                the policy will be updated.
              </p>

              <h2>8. Contact Us</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at:
              </p>
              <p>
                Email:{" "}
                <a href="mailto:helpdesk@safaixyz.com">helpdesk@safaixyz.com</a>
                <br />
                Website: <a href="https://www.safaixyz.com">www.safaixyz.com</a>
                <br />
                Phone: +91-8006305606
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;

import React from 'react'

function BackToTop() {
  return (
    <>
    <a href="#" className="btn btn-primary px-3 back-to-top"><i className="fa fa-angle-double-up"></i></a>
    </>
  )
}

export default BackToTop
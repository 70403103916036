import React, { useState, useEffect } from "react";
import "../css/VendorForm.css";
import { APIEndtPoint } from "./Apis";


const VendorForm = () => {
  const [vendor, setVendor] = useState({
    name: "",
    phone: "",
    address: "",
    services: [],
  });

  const [addresses, setAddresses] = useState([]);
  const [services, setServices] = useState([]);
  const [servicePrices, setServicePrices] = useState({});
  const [submitMessage, setSubmitMessage] = useState(""); 
  const [isSuccess, setIsSuccess] = useState(null); 

  
  useEffect(() => {
    fetch(APIEndtPoint + '/addresses')
      .then((response) => response.json())
      .then((data) => setAddresses(data))
      .catch((error) => console.error('Error fetching addresses:', error));
  }, []);

  
  useEffect(() => {
    fetch(APIEndtPoint + '/services')
      .then((response) => response.json())
      .then((data) => setServices(data))
      .catch((error) => console.error("Error fetching services:", error));
  }, []);

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVendor({ ...vendor, [name]: value });
  };

 
  const handleServiceChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setVendor({ ...vendor, services: [...vendor.services, value] });
      setServicePrices({ ...servicePrices, [value]: "" });
    } else {
      setVendor({
        ...vendor,
        services: vendor.services.filter((service) => service !== value),
      });
      const updatedPrices = { ...servicePrices };
      delete updatedPrices[value];
      setServicePrices(updatedPrices);
    }
  };

  
  const handleServicePriceChange = (e, service) => {
    const { value } = e.target;
    setServicePrices({ ...servicePrices, [service]: value });
  };

  
  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedServices = vendor.services.map((service) => ({
      service_name: service,
      price: servicePrices[service] || 0,
    }));

    const postData = {
      vendor: {
        name: vendor.name,
        phone_number: vendor.phone,
        location: vendor.address,
        sub_location: "phase-3",
        services_attributes: formattedServices,
      },
    };

    fetch(APIEndtPoint + '/vendors', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Vendor added successfully:', data);
        
        
        setSubmitMessage("Thank you! Vendor added successfully.");
        setIsSuccess(true); 
        
       
        setVendor({
          name: "",
          phone: "",
          address: "",
          services: [],
        });
        setServicePrices({});
      })
      .catch((error) => {
        console.error('Error submitting the form:', error);
        
       
        setSubmitMessage("Error submitting the form.");
        setIsSuccess(false);
      });
  };

  return (
    <div className="vendor-form-container">
      <h2>Vendor Details</h2>
      <form onSubmit={handleSubmit}>
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={vendor.name}
          onChange={handleInputChange}
          required
        />
        <br />
        <br />

        <label>Phone:</label>
        <input
          type="tel"
          name="phone"
          value={vendor.phone}
          onChange={handleInputChange}
          required
        />
        <br />
        <br />

        <label>Cities:</label>
        <select
          name="address"
          value={vendor.address}
          onChange={handleInputChange}
          required
        >
          <option value="">Select City</option>
          {addresses.length > 0 ? (
            addresses.map((address) => (
              <option key={address.id} value={address.name}>
                {address.name}
              </option>
            ))
          ) : (
            <option>Loading...</option>
          )}
        </select>
        <br />
        <br />

        <label>Services:</label>
        {services.map((service) => (
          <div key={service.id}>
            <input
              type="checkbox"
              value={service.service_name}
              onChange={handleServiceChange}
            />
            {service.service_name}
            {vendor.services.includes(service.service_name) && (
              <div>
                <label>Price: </label>
                <input
                  type="number"
                  value={servicePrices[service.service_name] || ""}
                  onChange={(e) =>
                    handleServicePriceChange(e, service.service_name)
                  }
                  placeholder="Enter price"
                  required
                />
              </div>
            )}
          </div>
        ))}

        <br />
        <br />
        <button type="submit">Submit</button>
      </form>

      
      <div className="submit-message" style={{ marginTop: "20px" }}>
        {submitMessage && (
          <p style={{ color: isSuccess ? "green" : "red" }}>{submitMessage}</p>
        )}
      </div>
    </div>
  );
};

export default VendorForm;
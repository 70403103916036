import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Dashboard.css";
import Services from "./ServiceComponent";
import Account from "./AccountComponent";
import CurrentVendorServices from "./CurrentVendorServices";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("services");
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    // Check if the user is authenticated
    const token = localStorage.getItem("userToken");
    if (!token) {
      // If no token, redirect to the registration page
      navigate("/registration");
    }
  }, [navigate]);

  const handleLogout = () => {
    // Clear the user token and redirect to the homepage
    localStorage.removeItem("userToken");
    navigate("/registration");
  };

  const renderContent = () => {
    switch (activeTab) {
      case "services":
        return <Services />;
      case "account":
        return <Account />;
      case "vendorServices":
        return <CurrentVendorServices/>;
      default:
        return null;
    }
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  return (
    <div className="dashboard-container">
      <button className="toggle-button" onClick={toggleSidebar}>
        ☰
      </button>
      <div className={`left-sidebar ${isSidebarVisible ? "visible" : "hidden"}`}>
        <h2>Dashboard</h2>
        <ul>
          <li onClick={() => setActiveTab("services")}>Services</li>
          <li onClick={() => setActiveTab("account")}>Profile</li>
          <li onClick={() => setActiveTab("vendorServices")}>My Service</li>
          <li onClick={handleLogout}>Logout</li>
        </ul>
      </div>
      <div className="right-content">{renderContent()}</div>
    </div>
  );
};

export default Dashboard;
// import React, { useState, useEffect } from "react";
// import { APIEndtPoint } from "./Apis";
// // import CurrentVendorServices from "./CurrentVendorServices";

// const ServiceComponent = ({onDataSubmit}) => {
//   const [selectedServices, setSelectedServices] = useState([]);
//   const [servicePrices, setServicePrices] = useState({});
//   const [selectedCity, setSelectedCity] = useState("");
//   const [servicesOffered, setServicesOffered] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [addressDetails, setAddressDetails] = useState({
//     address: "",
//     pinCode: "",
//     state: "",
//     locality: "",
//   });

//   // Fetch services from API
//   useEffect(() => {
//     const fetchServices = async () => {
//       try {
//         const response = await fetch(APIEndtPoint + "show_categories_product");
//         const data = await response.json();
//         setServicesOffered(data || []);
//       } catch (error) {
//         console.error("Error fetching services:", error);
//       }
//     };

//     fetchServices();
//   }, []);

//   // Fetch cities from API
//   useEffect(() => {
//     const fetchCities = async () => {
//       try {
//         const response = await fetch(APIEndtPoint + "v1/get_locations", {
//           method: "POST",
//         });
//         const data = await response.json();
//         setCities(data.locations || []);
//       } catch (error) {
//         console.error("Error fetching cities:", error);
//       }
//     };

//     fetchCities();
//   }, []);

//   const handleServiceSelection = (service) => {
//     setSelectedServices((prev) =>
//       prev.includes(service)
//         ? prev.filter((item) => item !== service)
//         : [...prev, service]
//     );
//   };

//   const handlePriceChange = (serviceId, price) => {
//     setServicePrices((prev) => ({
//       ...prev,
//       [serviceId]: price,
//     }));
//   };

//   const handleAddressChange = (e) => {
//     const { name, value } = e.target;
//     setAddressDetails({ ...addressDetails, [name]: value });
//   };

//   const handleAddressSubmit = async (e) => {
//     e.preventDefault();
//     if (selectedServices.length === 0) {
//       alert("Please select at least one service before submitting.");
//       return;
//     }
  
//     const payload = {
//       vendor_address: {
//         address1: addressDetails.address,
//         pin_code: addressDetails.pinCode,
//         city: selectedCity,
//         locality: addressDetails.locality,
//       },
//       products: selectedServices.map((serviceName) => {
//         const service = servicesOffered.find((s) => s.name === serviceName);
//         return {
//           product_id: service.id, // Use product_id as per API requirements
//           price: parseFloat(servicePrices[service.id]) || 0, // Ensure price is a number
//         };
//       }),
//       address_id: selectedCity,
//     };
//   console.log('********************************selected city', payload)

//     try {
//       const response = await fetch(
//         APIEndtPoint + "v1/vendors/add_vendor_address_services",
//         {
//           method: "POST",
//           headers: {
//             Authorization: localStorage.getItem("userToken"),
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(payload),
//         }
//       );
//       const result = await response.json();
//       console.log('llllllllllllllll', result)
//       // Ensure that response status is checked before proceeding
//       if (response.ok) {
//         // const result = await response.json();
//         alert("Address details and services submitted successfully!");
        
//         setSelectedServices([]);
//         setServicePrices({});
//         setAddressDetails({
//           address: "",
//           pinCode: "",
//           state: "",
//           locality: "",
//         });

//         window.location.reload();
//       } else {
//         const errorData = await response.json();
//         console.error("Submission error:", errorData);
//         alert("There was an error submitting your details. Please try again.");
//       }
//     } catch (error) {
//       console.error("Network error:", error);
//       alert("There was a network error. Please try again later.");
//     }
//   };
  
//   return (
//     <>
//       {/* <div className="tab-content">
//         <h3>My Services</h3>
//         <CurrentVendorServices/>
//       </div> */}
//       <div className="tab-content">
//         <h3>Add New Service</h3>
//         <div className="services-section">
//           <div className="services-offered">
//             <h4>Services We Offer</h4>
//             <ul>
//               {servicesOffered.map((service) => (
//                 <li key={service.id}>
//                   <label>
//                     <input
//                       type="checkbox"
//                       checked={selectedServices.includes(service.name)}
//                       onChange={() => handleServiceSelection(service.name)}
//                     />
//                     {service.name}
//                   </label>
//                   {selectedServices.includes(service.name) && (
//                     <input
//                       type="number"
//                       placeholder="Enter price"
//                       value={servicePrices[service.id] || ""}
//                       onChange={(e) =>
//                         handlePriceChange(service.id, e.target.value)
//                       }
//                       className="price-input"
//                     />
//                   )}
//                 </li>
//               ))}
//             </ul>
//             <p>
//               Selected Services:{" "}
//               {selectedServices.length > 0
//                 ? selectedServices.join(", ")
//                 : "None"}
//             </p>
//           </div>
//           <div className="add-address">
//             <h4>Add Address</h4>
//             <form onSubmit={handleAddressSubmit}>
//               <div className="form-group">
//                 <label htmlFor="city">Select City</label>
//                 <select
//                   id="city"
//                   name="city"
//                   value={selectedCity}
//                   onChange={(e) =>
//                    setSelectedCity(e.target.value)}
//                   required
//                 >
//                   <option value="">--Select City--</option>
//                   {cities.map((city) => (
//                     <option key={city.id} value={city.id}>
//                       {city.name}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="form-group">
//                 <label htmlFor="address">Address</label>
//                 <input
//                   type="text"
//                   id="address"
//                   name="address"
//                   placeholder="Enter your address"
//                   value={addressDetails.address}
//                   onChange={handleAddressChange}
//                   required
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="pinCode">Pin Code</label>
//                 <input
//                   type="text"
//                   id="pinCode"
//                   name="pinCode"
//                   placeholder="Enter pin code"
//                   value={addressDetails.pinCode}
//                   onChange={handleAddressChange}
//                   required
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="locality">Locality</label>
//                 <input
//                   type="text"
//                   id="locality"
//                   name="locality"
//                   placeholder="Enter locality"
//                   value={addressDetails.locality}
//                   onChange={handleAddressChange}
//                   required
//                 />
//               </div>
//               <button type="submit" className="submit-btn">
//                 Submit
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ServiceComponent;




// import React, { useState, useEffect } from "react";
// import { APIEndtPoint } from "./Apis";

// const ServiceComponent = ({ onDataSubmit }) => {
//   const [selectedServices, setSelectedServices] = useState([]);
//   const [selectedService, setSelectedService] = useState(null); // To track currently selected service
//   const [servicePrices, setServicePrices] = useState({});
//   const [selectedCity, setSelectedCity] = useState("");
//   const [servicesOffered, setServicesOffered] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [addressDetails, setAddressDetails] = useState({
//     address: "",
//     pinCode: "",
//     state: "",
//     locality: "",
//   });

//   // Fetch services from API
//   useEffect(() => {
//     const fetchServices = async () => {
//       try {
//         const response = await fetch(APIEndtPoint + "show_categories_product");
//         const data = await response.json();
//         setServicesOffered(data || []);
//       } catch (error) {
//         console.error("Error fetching services:", error);
//       }
//     };

//     fetchServices();
//   }, []);

//   // Fetch cities from API
//   useEffect(() => {
//     const fetchCities = async () => {
//       try {
//         const response = await fetch(APIEndtPoint + "v1/get_locations", {
//           method: "POST",
//         });
//         const data = await response.json();
//         setCities(data.locations || []);
//       } catch (error) {
//         console.error("Error fetching cities:", error);
//       }
//     };

//     fetchCities();
//   }, []);

//   const handleServiceSelection = (service) => {
//     setSelectedService(service); // Set currently selected service
//     setSelectedServices((prev) =>
//       prev.includes(service.name)
//         ? prev.filter((item) => item !== service.name)
//         : [...prev, service.name]
//     );
//   };

//   const handleProductSelection = (productId, price) => {
//     setServicePrices((prev) => ({
//       ...prev,
//       [productId]: price,
//     }));
//   };

//   const handleAddressChange = (e) => {
//     const { name, value } = e.target;
//     setAddressDetails({ ...addressDetails, [name]: value });
//   };

//   const handleAddressSubmit = async (e) => {
//     e.preventDefault();
//     if (selectedServices.length === 0) {
//       alert("Please select at least one service before submitting.");
//       return;
//     }

//     const payload = {
//       vendor_address: {
//         address1: addressDetails.address,
//         pin_code: addressDetails.pinCode,
//         city: selectedCity,
//         locality: addressDetails.locality,
//       },
//       products: selectedServices.map((serviceName) => {
//         const service = servicesOffered.find((s) => s.name === serviceName);
//         return {
//           product_id: service.id,
//           price: parseFloat(servicePrices[service.id]) || 0,
//         };
//       }),
//       address_id: selectedCity,
//     };

//     try {
//       const response = await fetch(
//         APIEndtPoint + "v1/vendors/add_vendor_address_services",
//         {
//           method: "POST",
//           headers: {
//             Authorization: localStorage.getItem("userToken"),
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(payload),
//         }
//       );
//       const result = await response.json();
//       if (response.ok) {
//         alert("Address details and services submitted successfully!");
//         setSelectedServices([]);
//         setServicePrices({});
//         setAddressDetails({
//           address: "",
//           pinCode: "",
//           state: "",
//           locality: "",
//         });
//         window.location.reload();
//       } else {
//         alert("There was an error submitting your details. Please try again.");
//       }
//     } catch (error) {
//       console.error("Network error:", error);
//       alert("There was a network error. Please try again later.");
//     }
//   };

//   return (
//     <>
//       <div className="tab-content">
//         <h3>Add New Service</h3>
//         <div className="services-section">
//           <div className="services-offered">
//             <h4>Services We Offer</h4>
//             <ul>
//               {servicesOffered.map((service) => (
//                 <li key={service.id}>
//                   <label>
//                     <input
//                       type="checkbox"
//                       checked={selectedServices.includes(service.name)}
//                       onChange={() => handleServiceSelection(service)}
//                     />
//                     {service.name}
//                   </label>
//                 </li>
//               ))}
//             </ul>
//           </div>

//           {selectedService && (
//             <div className="products-section">
//               <h4>Products for {selectedService.name}</h4>
//               <ul>
//                 {selectedService.products.map((product) => (
//                   <li key={product.id}>
//                     <label>
//                       <input
//                         type="checkbox"
//                         onChange={(e) =>
//                           handleProductSelection(
//                             product.id,
//                             e.target.checked ? product.price : 0
//                           )
//                         }
//                       />
//                       {product.title}
//                     </label>
//                     <input
//                       type="number"
//                       placeholder="Enter price"
//                       value={servicePrices[product.id] || ""}
//                       onChange={(e) =>
//                         handleProductSelection(product.id, e.target.value)
//                       }
//                       className="price-input"
//                     />
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           )}

//           <div className="add-address">
//             <h4>Add Address</h4>
//             <form onSubmit={handleAddressSubmit}>
//               <div className="form-group">
//                 <label htmlFor="city">Select City</label>
//                 <select
//                   id="city"
//                   name="city"
//                   value={selectedCity}
//                   onChange={(e) => setSelectedCity(e.target.value)}
//                   required
//                 >
//                   <option value="">--Select City--</option>
//                   {cities.map((city) => (
//                     <option key={city.id} value={city.id}>
//                       {city.name}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="form-group">
//                 <label htmlFor="address">Address</label>
//                 <input
//                   type="text"
//                   id="address"
//                   name="address"
//                   placeholder="Enter your address"
//                   value={addressDetails.address}
//                   onChange={handleAddressChange}
//                   required
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="pinCode">Pin Code</label>
//                 <input
//                   type="text"
//                   id="pinCode"
//                   name="pinCode"
//                   placeholder="Enter pin code"
//                   value={addressDetails.pinCode}
//                   onChange={handleAddressChange}
//                   required
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="locality">Locality</label>
//                 <input
//                   type="text"
//                   id="locality"
//                   name="locality"
//                   placeholder="Enter locality"
//                   value={addressDetails.locality}
//                   onChange={handleAddressChange}
//                   required
//                 />
//               </div>
//               <button type="submit" className="submit-btn">
//                 Submit
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ServiceComponent;
import React, { useState, useEffect } from "react";
import { APIEndtPoint } from "./Apis";

const ServiceComponent = () => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [activeServiceId, setActiveServiceId] = useState(null); // Track active service for showing products
  const [servicePrices, setServicePrices] = useState({});
  const [selectedCity, setSelectedCity] = useState("");
  const [servicesOffered, setServicesOffered] = useState([]);
  const [cities, setCities] = useState([]);
  const [addressDetails, setAddressDetails] = useState({
    address: "",
    pinCode: "",
    state: "",
    locality: "",
  });

  // Fetch services from API
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(APIEndtPoint + "show_categories_product");
        const data = await response.json();
        setServicesOffered(data || []);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

  // Fetch cities from API
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await fetch(APIEndtPoint + "v1/get_locations", {
          method: "POST",
        });
        const data = await response.json();
        setCities(data.locations || []);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, []);

  const handleServiceSelection = (serviceId) => {
    setSelectedServices((prev) =>
      prev.includes(serviceId)
        ? prev.filter((id) => id !== serviceId)
        : [...prev, serviceId]
    );
    setActiveServiceId(serviceId === activeServiceId ? null : serviceId); // Toggle active service
  };

  const handleProductSelection = (productId, price) => {
    setServicePrices((prev) => ({
      ...prev,
      [productId]: price,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressDetails({ ...addressDetails, [name]: value });
  };

  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    if (selectedServices.length === 0) {
      alert("Please select at least one service before submitting.");
      return;
    }

    const payload = {
      vendor_address: {
        address1: addressDetails.address,
        pin_code: addressDetails.pinCode,
        city: selectedCity,
        locality: addressDetails.locality,
      },
      products: selectedServices.map((serviceId) => {
        const service = servicesOffered.find((s) => s.id === serviceId);
        return {
          product_id: service.id,
          price: parseFloat(servicePrices[service.id]) || 0,
        };
      }),
      address_id: selectedCity,
    };

    try {
      const response = await fetch(
        APIEndtPoint + "v1/vendors/add_vendor_address_services",
        {
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("userToken"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      if (response.ok) {
        alert("Address details and services submitted successfully!");
        setSelectedServices([]);
        setServicePrices({});
        setAddressDetails({
          address: "",
          pinCode: "",
          state: "",
          locality: "",
        });
        window.location.reload();
      } else {
        alert("There was an error submitting your details. Please try again.");
      }
    } catch (error) {
      console.error("Network error:", error);
      alert("There was a network error. Please try again later.");
    }
  };

  return (
    <div className="tab-content">
      <h3>Add New Service</h3>
      <div className="services-section">
        {servicesOffered.map((service) => (
          <div key={service.id} className="services-offered">
            <label>
              <input
                type="checkbox"
                checked={selectedServices.includes(service.id)}
                onChange={() => handleServiceSelection(service.id)}
              />
              {service.name}
            </label>

            {activeServiceId === service.id && (
              <div className="products-section">
                <h4>Products for {service.name}</h4>
                <ul>
                  {service.products.map((product) => (
                    <li key={product.id}>
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleProductSelection(
                              product.id,
                              e.target.checked ? product.price : 0
                            )
                          }
                        />
                        {product.title}
                      </label>
                      <input
                        type="number"
                        placeholder="Price"
                        value={servicePrices[product.id] || ""}
                        onChange={(e) =>
                          handleProductSelection(product.id, e.target.value)
                        }
                        className="price-input"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}

        <div className="add-address">
          <h4>Add Address</h4>
          <form onSubmit={handleAddressSubmit}>
            <div className="form-group">
              <label htmlFor="city">Select City</label>
              <select
                id="city"
                name="city"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
                required
              >
                <option value="">--Select City--</option>
                {cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Enter your address"
                value={addressDetails.address}
                onChange={handleAddressChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="pinCode">Pin Code</label>
              <input
                type="text"
                id="pinCode"
                name="pinCode"
                placeholder="Enter pin code"
                value={addressDetails.pinCode}
                onChange={handleAddressChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="locality">Locality</label>
              <input
                type="text"
                id="locality"
                name="locality"
                placeholder="Enter locality"
                value={addressDetails.locality}
                onChange={handleAddressChange}
                required
              />
            </div>
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ServiceComponent;

import React from 'react'
import Footer from './Footer'
import blog1Image from '../img/blog-1.jpg'
import CopyRight from './CopyRight'

function HouseCleaningSubCategory() {
  return (
    <div>
      {/* Header start */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 bg-secondary-1 d-none d-lg-block">
            <a href="/" className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
              <h1 className="m-0 display-3 text-primary">SafaiXYZ</h1>
            </a>
          </div>
          <div className="col-lg-9">
            <div className="row bg-dark d-none d-lg-flex">
              <div className="col-lg-7 text-left text-white">
                <div className="h-100 d-inline-flex align-items-center border-right border-primary py-2 px-3">
                  <i className="fa fa-envelope text-primary mr-2"></i>
                  <small> helpdesk@safaixyz.com</small>
                </div>
                <div className="h-100 d-inline-flex align-items-center py-2 px-2">
                  <i className="fa fa-phone-alt text-primary mr-2"></i>
                  <small>+91-8006305606</small>
                </div>
              </div>
              <div className="col-lg-5 text-right">
                <div className="d-inline-flex align-items-center pr-2">
                  <a className="text-primary p-2" href="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a className="text-primary p-2" href="">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a className="text-primary p-2" href="">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a className="text-primary p-2" href="">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a className="text-primary p-2" href="">
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-white navbar-light p-0">
              <a href="" className="navbar-brand d-block d-lg-none">
                <h1 className="m-0 display-4 text-primary">SafaiXYZ</h1>
              </a>
              <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse" >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse" >
                <div className="navbar-nav mr-auto py-0">
                  <a href="/" className="nav-item nav-link">Home</a>
                  <a href="/about" className="nav-item nav-link">About</a>
                  <a href="/service" className="nav-item nav-link">Service</a>
                  <a href="/projects" className="nav-item nav-link">Project</a>
                  <a href="/blog" className="nav-item nav-link">Blogs</a>
                  <a href="contact" className="nav-item nav-link">Contact</a>
                </div>
                <a href="" className="btn btn-primary mr-3 d-none d-lg-block" >Get A Quote</a>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* Header end */}

      {/* Page Header Start */}
      <div className="container-fluid bg-primary py-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="display-4 mb-4 mb-md-0 text-secondary-1 text-uppercase">
                House Cleanning<br/>Sub-Categories
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <a className="btn btn-sm btn-outline-light" href="/">Home</a>
                <i className="fas fa-angle-double-right text-light mx-2"></i>
                <a className="btn btn-sm btn-outline-light disabled" href="/sub-category">Sub Categories</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Page Header End */}

      {/* Sub Category start  */}
      <div className="container">
        <div className="row m-4">
          <div className="row col-7">
            <div className='col-lg-12 mt-4'>
              <h3>1 BHK</h3>
              <hr />
              <div style={{border: '1px solid black', padding: '8px' }}>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia, et. Totam recusandae consectetur, expedita fuga unde libero repellat reprehenderit nemo!
                </p>
                <div className="main-price">
                  <div className="price-with-offer" style={{border: '1px solid black', padding: '4px'}}>
                    <b>₹ 200</b>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-12 mt-4'>
              <h3>2 BHK</h3>
              <hr />
              <div style={{border: '1px solid black', padding: '8px' }}>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia, et. Totam recusandae consectetur, expedita fuga unde libero repellat reprehenderit nemo!
                </p>
                <div className="main-price">
                  <div className="price-with-offer" style={{border: '1px solid black', padding: '4px'}}>
                    <b>₹ 400</b>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-12 mt-4'>
              <h3>3 BHK</h3>
              <hr />
              <div style={{border: '1px solid black', padding: '8px' }}>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia, et. Totam recusandae consectetur, expedita fuga unde libero repellat reprehenderit nemo!
                </p>
                <div className="main-price">
                  <div className="price-with-offer" style={{border: '1px solid black', padding: '4px'}}>
                    <b>₹ 600</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="cart-totals mt-5">
              <h3>Estimated Price</h3>
              <form action="#" method="get" acceptCharset="utf-8">
                <table>
                  <tbody>
                    <tr>
                      <td>Subtotal</td>
                      <td className="subtotal">$200</td>
                    </tr>
                    <tr>
                      <td>Added Sevices</td>
                      <td className="free-shipping">
                        <ul style={{ listStyleType: "none", display: "flex", alignItems: "start", flexDirection: "column" }}>
                          <li>Product 1</li>
                          <li>Product 1</li>
                          <li>Product 1</li>
                          <li>Product 1</li>
                        </ul>
                      </td>
                    </tr>
                    <tr className="total-row">
                      <td>Total</td>
                      <td className="price-total">$200</td>
                    </tr>
                  </tbody>
                </table>
                <div className="btn-cart-totals">
                  <a href="/basic-details" className="checkout btn btn-primary round-black-btn" title="Proceed to Checkout">Add Basic Details</a>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Mini Services Start */}
        <div className="row align-items-end mb-4">
          <div className="col-lg-12">
            <h1 className="section-title-1 mb-3">Special Mini Services</h1>
          </div>
        </div>
        <div className="row">
          {Array.from({ length: 6 }).map((_, index) => (
            <div className="col-lg-4 col-md-6 mb-3" key={index}>
              <div className="position-relative mb-4 popular-item-box">
              <img className="img-fluid rounded w-50" src={blog1Image} alt="Mini Service"/>
                <div className="service-box">
                  <span className="mb-n1">Mini Service</span>
                  <div className="service-price-1">
                    <small className="text-uppercase m-1">₹ 949</small>
                    <a className="btn-sm btn-info btn-border" href="#">Add</a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Mini Services End */}
      </div>
      {/* Sub Category End  */}
      <Footer/>
      <CopyRight/>
    </div>
  )
}

export default HouseCleaningSubCategory
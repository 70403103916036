import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Registration.css";
import { APIEndtPoint } from "./Apis";

const Registration = () => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
    email: "",
    confirmPassword: "",
  });

  const [errorMessages, setErrorMessages] = useState([]);  
  const navigate = useNavigate();
  const signUpUrl = `${APIEndtPoint}v1/signup`;
  const loginUrl = `${APIEndtPoint}v1/login`;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = isSignUp ? signUpUrl : loginUrl;
    const payload = isSignUp
      ? {
          phone_number: formData.phone,
          email: formData.email,
          password: formData.password,
          password_confirmation: formData.confirmPassword,
          role: "vendor",
        }
      : {
          email: formData.email,
          password: formData.password,
        };

    if (isSignUp && formData.password !== formData.confirmPassword) {
      setErrorMessages(["Passwords do not match!"]);
      return;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();

        if (errorData.error && Array.isArray(errorData.error)) {
          setErrorMessages(errorData.error);  
        } else {
          setErrorMessages(["An unexpected error occurred. Please try again."]); 
        }
        return;
      }

      const data = await response.json();
      console.log(isSignUp ? "Sign-up successful:" : "Login successful:", data);
      setErrorMessages([]); 

      localStorage.setItem("userToken", data.token);
      console.log("Token saved:", data.token);

      navigate("/dashboard");
    } catch (error) {
      console.error(isSignUp ? "Sign-up failed:" : "Login failed:", error);
      setErrorMessages([error.message]); 
    }
  };

  return (
    <div className="registration-container">
      <div className="registration-card">
        <h2>{isSignUp ? "Vendor Sign Up" : "Sign In"}</h2>
        <form onSubmit={handleSubmit}>
          {isSignUp && (
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={handleChange}
                required={isSignUp}
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          {isSignUp && (
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm your password"
                value={formData.confirmPassword}
                onChange={handleChange}
                required={isSignUp}
              />
            </div>
          )}
          <button type="submit" className="submit-btn">
            {isSignUp ? "Sign Up" : "Sign In"}
          </button>
        </form>

        {errorMessages.length > 0 && (
          <div className="error-messages">
            {errorMessages.map((error, index) => (
              <p key={index} className="error-message">{error}</p>
            ))}
          </div>
        )}

        <p className="toggle-text">
          {isSignUp ? "Already have an account?" : "Don't have an account?"}
          <span onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp ? " Sign In" : " Sign Up"}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Registration;
import React from 'react'
import SubCtegoryDetails from './SubCtegoryDetails'
import blog1Image from '../img/blog-1.jpg'

function SubCategory() {
  return (
    <>
      {/* Header start */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 bg-secondary-1 d-none d-lg-block">
            <a href="/" className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
              <h1 className="m-0 display-3 text-primary">SafaiXYZ</h1>
            </a>
          </div>
          <div className="col-lg-9">
            <div className="row bg-dark d-none d-lg-flex">
              <div className="col-lg-7 text-left text-white">
                <div className="h-100 d-inline-flex align-items-center border-right border-primary py-2 px-3">
                  <i className="fa fa-envelope text-primary mr-2"></i>
                  <small> helpdesk@safaixyz.com</small>
                </div>
                <div className="h-100 d-inline-flex align-items-center py-2 px-2">
                  <i className="fa fa-phone-alt text-primary mr-2"></i>
                  <small>+91-8006305606</small>
                </div>
              </div>
              <div className="col-lg-5 text-right">
                <div className="d-inline-flex align-items-center pr-2">
                  <a className="text-primary p-2" href="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a className="text-primary p-2" href="">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a className="text-primary p-2" href="">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a className="text-primary p-2" href="">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a className="text-primary p-2" href="">
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-white navbar-light p-0">
              <a href="" className="navbar-brand d-block d-lg-none">
                <h1 className="m-0 display-4 text-primary">SafaiXYZ</h1>
              </a>
              <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse" >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse" >
                <div className="navbar-nav mr-auto py-0">
                  <a href="/" className="nav-item nav-link">Home</a>
                  <a href="/about" className="nav-item nav-link">About</a>
                  <a href="/service" className="nav-item nav-link">Service</a>
                  <a href="/projects" className="nav-item nav-link">Project</a>
                  <a href="/blog" className="nav-item nav-link">Blogs</a>
                  <a href="contact" className="nav-item nav-link">Contact</a>
                </div>
                <a href="" className="btn btn-primary mr-3 d-none d-lg-block" >Get A Quote</a>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* Header end */}
      
      {/* Page Header Start */}
      <div className="container-fluid bg-primary py-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="display-4 mb-4 mb-md-0 text-secondary-1 text-uppercase">
                Sub Categories
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <a className="btn btn-sm btn-outline-light" href="/">Home</a>
                <i className="fas fa-angle-double-right text-light mx-2"></i>
                <a className="btn btn-sm btn-outline-light disabled" href="/sub-category">Sub Categories</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Page Header End */}

      {/* Service Details Start  */}
      <SubCtegoryDetails/>
      {/* Service Details End  */}

      {/* Services Start */}
      <div className="container-fluid">
        <div className="container popular-service-bg">
          <div className="row align-items-end mb-4">
            <div className="col-lg-12">
              <hr />
              <h1 className="section-title-1 mb-3">Popular Services</h1>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              {/* Popular Services */}
              <div className="row">
                {/* {data && data.map((service, index) => ( */}
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="box-with-border">
                      <div className="position-relative mb-4">
                      {/* <img className="img-fluid rounded w-25" src={service.images.url} alt="Service Example"/> */}
                      <img className="img-fluid rounded w-25" src={blog1Image} alt="Service Example"/>
                        <div className="service-box w-70">
                          {/* <span className="mb-n1">{service.title}</span> */}
                          <span className="mb-n1">Popular service 1</span>
                          <div className="service-price-1">
                            {/* <small className="text-uppercase m-1">₹ {service.price}</small> */}
                            <small className="text-uppercase m-1">₹ 200</small>
                          </div>
                        </div>
                        <div className="blog-date">
                          <h4 className="font-weight-bold mb-n1"></h4>
                          <small className="text-white text-uppercase"></small>
                        </div>
                        <div className="service-details">
                          <div className="service-listing">
                            <ul className="service-box-listing">
                              {/* <li>{service.description}</li>
                              <li>{service.description}</li>
                              <li>{service.description}</li> */}
                              <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit voluptates rem dolore similique alias voluptate tempore, quidem architecto autem eos!</li>
                              <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit voluptates rem dolore similique alias voluptate tempore, quidem architecto autem eos!</li>
                              <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit voluptates rem dolore similique alias voluptate tempore, quidem architecto autem eos!</li>
                            </ul>
                          </div>
                          <div className="add-btn">
                            <small className="text-white text-uppercase">
                              {/* <button className="btn-sm btn-info btn-border mr-1" onClick={() => addToCart(service)}>
                                {isProductInCart(service.id) ? 'Remove' : 'Add'}
                              </button> */}
                              <button>Add</button>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                {/* ))} */}
              </div>
              {/* Special Mini Services */}
              <div className="row align-items-end mb-4">
                <div className="col-lg-12">
                  <h1 className="section-title-1 mb-3">Special Mini Services</h1>
                  <hr />
                </div>
              </div>
              <div className="row">
                {Array.from({ length: 6 }).map((_, index) => (
                  <div className="col-lg-4 col-md-6 mb-3" key={index}>
                    <div className="position-relative mb-4 popular-item-box">
                    <img className="img-fluid rounded w-50" src={blog1Image} alt="Mini Service"/>
                      <div className="service-box">
                        <span className="mb-n1">Mini Service</span>
                        <div className="service-price-1">
                          <small className="text-uppercase m-1">₹ 949</small>
                          <a className="btn-sm btn-info btn-border" href="#">Add</a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="cart-totals">
                <h3>Estimated Price</h3>
                <form action="#" method="get" acceptCharset="utf-8">
                  <table>
                    <tbody>
                      <tr>
                        <td>Subtotal</td>
                        {/* <td className="subtotal">${subtotal.toFixed(2)}</td> */}
                        <td className="subtotal">$ 200</td>
                      </tr>
                      <tr>
                        <td>Added Sevices</td>
                        <td className="free-shipping">
                          <ul style={{ listStyleType: "none", display: "flex", alignItems: "start", flexDirection: "column" }}>
                            {/* {cartItems.map((item, index) => (
                              <li key={index}>{item.title}</li>
                            ))} */}
                            <li>Product 1</li>
                          </ul>
                        </td>
                      </tr>
                      <tr className="total-row">
                        <td>Total</td>
                        {/* <td className="price-total">${subtotal.toFixed(2)}</td> */}
                        <td className="price-total">$ 200</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="btn-cart-totals">
                    <a href="/basic-details" className="checkout btn btn-primary round-black-btn" title="Proceed to Checkout">Add Basic Details</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services End */}
    </>
  )
}

export default SubCategory
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { APIEndtPoint } from './Apis';

function SignUp() {
  const [formData, setFormData] = useState({
    email: '',
    phoneNumber: '',
    password: ''
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = {
      email: formData.email,
      phone_number: formData.phoneNumber, 
      password: formData.password
    };

    try {
      const response = await fetch(APIEndtPoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData && errorData.error) {
          const newErrors = {};
          errorData.error.forEach((err) => {
            if (err.toLowerCase().includes("email")) {
              newErrors.email = err;
            }
            if (err.toLowerCase().includes("phone number")) {
              newErrors.phoneNumber = err;
            }
          });
          setErrors(newErrors);
        } else {
          throw new Error('Failed to sign up');
        }
      } else {
        const result = await response.json();
        console.log('Success:', result);
        localStorage.setItem('userToken', result.token);
        // alert('Signup successful!');
        setFormData({ email: '', phoneNumber: '', password: '' });
        setErrors({}); 
        navigate('/checkout');
      }
    } catch (error) {
      console.error('Error during signup:', error);
      alert('Signup failed. Please try again.');
    }
  };
  
  return (
    <>
      <style jsx>{`
        form {
          max-width: 400px;
          margin: 200px auto;
          padding: 20px;
          border: 1px solid #ccc;
          border-radius: 8px;
          background-color: #f9f9f9;
        }
        h2 {
          text-align: center;
          margin-bottom: 20px;
        }
        label {
          display: block;
          margin-top: 10px;
          margin-bottom: 5px;
        }
        input {
          width: 100%;
          padding: 8px;
          margin-bottom: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        button {
          width: 100%;
          padding: 10px;
          background-color: #007BFF;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
        }
        button:hover {
          background-color: #0056b3;
        }
        .error {
          color: red;
        }
        .signin-link {
          display: block;
          text-align: center;
          margin-top: 10px;
        }
      `}</style>
      <form onSubmit={handleSubmit}>
        <h2>Sign Up</h2>
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="E-mail" required />
        {errors.email && <div className="error">{errors.email}</div>}
        <br />
        <label htmlFor="phoneNumber">Phone Number:</label>
        <input type="tel" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Phone Number" required />
        {errors.phoneNumber && <div className="error">{errors.phoneNumber}</div>}
        <br />
        <label htmlFor="password">Password:</label>
        <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password" required />
        {errors.password && <div className="error">{errors.password}</div>}
        <br />
        <button type="submit">Sign Up</button>
        <Link to="/signin" className="signin-link">Already have an account? Sign in here!</Link>
      </form>
    </>
  )
}

export default SignUp

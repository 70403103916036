import React, { useState, useEffect } from 'react';
import Testimonial from './Testimonial'
import PlanBox from './PlanBox'
import VideoModal from './VideoModal'
import "../css/header.css";
import Carousel from './Carousel'
import Header from './Header'
import Footer from './Footer';
import { APIEndtPoint } from "./Apis";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import Loader from './Loader';

function Home() {
  const [isApiWorking, setIsApiWorking] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(APIEndtPoint + "categories/search", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ category_type: "monthly_package" }),
        });

        if (!response.ok) {
          throw new Error('API check failed');
        }
      } catch (error) {
        console.error('Error:', error);
        setIsApiWorking(false);
      } 
    };

    fetchData();
  }, []);

  if (!isApiWorking) {
    return (
      <div className="maintenance-message">
        <Header data={'home'} />
        <Carousel />
        <VideoModal />
        <div className="maintenance-content text-center border bg-light m-3 py-5 shadow rounded">
          <FontAwesomeIcon icon={faTools} size="5x" className="text-muted" />
          <h1>Website Maintenance</h1>
          <p className="text-center">Sorry, we are currently undergoing maintenance. Please check back later.</p>
        </div>
        <Testimonial />
        <Footer />
      </div>
    );
  }
  return (
    <>
      <Header data={'home'} />
      <Carousel />
      <VideoModal />
      <PlanBox />
      <Testimonial />
      <Footer />
    </>
  )
}

export default Home


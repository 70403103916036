import React, { useState, useEffect } from "react";
import { APIEndtPoint } from "./Apis";
import PopularCategoryDetails from "./PopularCategoryDetails";
import Tabs from "./Tabs";

function PopularCategory({ addToCart, isProductInCart }) {
  const [dataPopularCategory, setPopularCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    const fetchPopularCategoryData = async () => {
      const response = await fetch(APIEndtPoint + "categories/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ category_type: [2, 5] }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Popular Category Response:", result);
        setPopularCategoryData(result);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    };
    fetchPopularCategoryData();
  }, []);

  const handleClick = (category) => {
    console.log("Handling click for category:", category);
    setSelectedCategory(category);
  };

  return (
    <>
      <div class="row popular-service-bg mb-2">
        <div className="col-lg-12">
          <h1 class="section-title-1 mb-4">Popular Services by Category</h1>
        </div>
        <ul className="tab-list border mb-2 col-lg-12">
          {dataPopularCategory && dataPopularCategory.map((category, index) => (
            <li
              key={category.id}
              className={`tabs ${selectedCategory === category ? "active-tabs" : ""}`}
              onClick={() => handleClick(category)}
            >
              <span class="mb-n1">{category.name}</span>
            </li>
          ))}
        </ul>
        {selectedCategory && (
          <Tabs
            data={[selectedCategory]}
            addToCart={addToCart}
            isProductInCart={isProductInCart}
            byPopularCategory={'byPopularCategory'}
          ></Tabs>
        )}
      </div>
    </>
  );
}

export default PopularCategory;

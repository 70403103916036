import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
// import blog1Image from "../img/blog-1.jpg";
// import Footer from "./Footer";
import "../css/buynow.css";
import { APIEndtPoint } from "./Apis";
import MiniSerices from "./MiniSerices";
import PopularCategory from "./PopularCategory";
import Tabs from "./Tabs";
// import CartDetails from "./CartDetails";
// import logoImage from "../img/logo-preview.png";
// import Logo from "../Pages/Logo";
import Header from "./Header";
import FullHouseCategory from "./FullHouseCategory";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function truncateText(text, numLines) {
  const lines = text.split("\n");
  if (lines.length > numLines) {
    return lines.slice(0, numLines);
  }
  return text;
}

function BuyNow() {
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [subtotal, setSubtotal] = useState(() =>
    parseFloat(localStorage.getItem("subtotal") || 0)
  );
  const [cartItems, setCartItems] = useState(
    () => JSON.parse(localStorage.getItem("cart")) || []
  );
  const [isCartModalVisible, setCartModalVisible] = useState(false);
  const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);
  const navigate = useNavigate();
  const query = useQuery();
  const categoryId = query.get("category_id");

  const [showBasicDetails, setShowBasicDetails] = useState(false);

  const removeFromCart = (productId) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== productId);
    const removedItem = cartItems.find((item) => item.id === productId);
    const newSubtotal =
      subtotal - parseFloat(removedItem.price * removedItem.quantity);

    setCartItems(updatedCartItems);
    setSubtotal(newSubtotal);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    localStorage.setItem("subtotal", newSubtotal.toString());
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(APIEndtPoint + "categories/" + categoryId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      });

      if (response.ok) {
        const result = await response.json();
        setData([result]);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    };
    fetchData();
  }, []);

  const addToCart = (product) => {
    const existingProductIndex = cartItems.findIndex(
      (item) => item.id === product.id
    );
    let newCartItems;
    let newSubtotal;

    if (existingProductIndex === -1) {
      newCartItems = [...cartItems, { ...product, quantity: 1 }];
      newSubtotal = subtotal + parseFloat(product.price);
    } else {
      newCartItems = [...cartItems];
      newCartItems.splice(existingProductIndex, 1);
      newSubtotal = subtotal - parseFloat(product.price);
    }

    setCartItems(newCartItems);
    setSubtotal(newSubtotal);
    localStorage.setItem("cart", JSON.stringify(newCartItems));
    localStorage.setItem("subtotal", newSubtotal.toString());
  };

  const isProductInCart = (productId) =>
    cartItems.some((item) => item.id === productId);

  const toggleContent = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const toggleCartModal = () => {
    console.log(isCartModalVisible, "******** 102");
    // setCartModalVisible(!isCartModalVisible);
  };

  const handleShowBasicDetails = () => {
    if (cartItems.length > 0) {
      setShowBasicDetails(true);
    }
  };

  const handleIncrease = (service) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === service.id
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
    const newSubtotal = subtotal + parseFloat(service.price);
    setCartItems(updatedCartItems);
    setSubtotal(newSubtotal);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    localStorage.setItem("subtotal", newSubtotal.toString());
  };

  const handleDecrease = (service) => {
    const updatedCartItems = cartItems
      .map((item) =>
        item.id === service.id
          ? { ...item, quantity: Math.max(item.quantity - 1, 0) }
          : item
      )
      .filter((item) => item.quantity > 0);
    const newSubtotal = subtotal - parseFloat(service.price);
    setCartItems(updatedCartItems);
    setSubtotal(newSubtotal);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    localStorage.setItem("subtotal", newSubtotal.toString());
  };
  return (
    <div>
      {/* Cart details start */}
      {/* Cart details end */}

      {/* Header start */}
      <Header cartItem={cartItems} />
      {/* Header end */}
      <div className="container-fluid">
        {/* <div className="dv-cart-box">
          <CartDetails
            subtotal={subtotal}
            cartItems={cartItems}
            toggleCartModal={toggleCartModal}
            totalItems={totalItems}
            removeFromCart={removeFromCart}
            showBasicDetails={showBasicDetails}
            handleShowBasicDetails={handleShowBasicDetails}
          />
        </div> */}
        <div className="dv-cart-box">
          <Link className="btn btn-primary" to="/cartdetails">
            <i className="fa fa-shopping-cart"></i>
            {totalItems > 0 && (
              <span className="cart-item-count">{totalItems}</span>
            )}
          </Link>
        </div>

        <div className="dv-cart-mobile-box">
          <Link className="btn btn-primary" to="/cartdetails">
            <i className="fa fa-shopping-cart"></i>
            {totalItems > 0 && (
              <span className="cart-item-count">{totalItems}</span>
            )}
          </Link>
        </div>

        {/* <div className="dv-offer">
                <div className="dv-icon">
                  <img src={WhatsAppImage} style={{ width: "5px;" }} />
                </div>
                <div className="dv-booking-content-1" style={{ display: "none;" }}>
                  <a href="https://wa.me/918006305606"  target="_blank" className="text-white">
                    +91-8006305606
                  </a>
                </div>
              </div> */}
      </div>
      {categoryId !== '4' && (
        <>
          {/* Services Start */}
          <div class="container-fluid">
            <div class="container">
              <div class="row popular-service-bg">
                {/* <div className="popular-service-bg"> */}
                {/* <RightTab data={data}></RightTab> */}
                {data &&
                  data.map((category) => (
                    <>
                      <>
                        <h1 class="section-title-1 mb-4 px-3">
                          {category.name} Services
                        </h1>
                      </>
                    </>
                  ))}
                </div>
              <div class="row">
                <Tabs
                  data={data}
                  addToCart={addToCart}
                  isProductInCart={isProductInCart}
                  handleIncrease={handleIncrease}
                  handleDecrease={handleDecrease}
                ></Tabs>
              </div>
              <div class="row mt-2"></div>
            </div>
          </div>

          {/* <div class="container-fluid">
            <div class="container">
              <PopularCategory
                addToCart={addToCart}
                isProductInCart={isProductInCart}
              ></PopularCategory>
            </div>
          </div> */}
          {/* Services End */}

          {/* mini services start  */}
          {/* <div class="row align-items-end mb-4"></div>
          <MiniSerices addToCart={addToCart} isProductInCart={isProductInCart} data={data} /> */}
          {/* mini services end  */}
        </>
      )}

      {categoryId == '4' && (
        <div class="container-fluid">
          <div class="container">
            <FullHouseCategory
              addToCart={addToCart}
              isProductInCart={isProductInCart}
            >
            </FullHouseCategory>
          </div>
        </div>
      )}
    </div>
  );
}

export default BuyNow;

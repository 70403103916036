import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../img/logo-preview.png";

function Footer() {
  return (
    <>
      <div className="container-fluid text-white mt-5 py-5 bg-dark px-sm-3 px-md-5 text-white">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-5">
            {/* <Link to='/' className="navbar-brand">
              <h1 className="m-0 mt-n3 display-4 text-white">SafaiXYZ</h1>
            </Link> */}
            <a href="/" className="d-block" style={{ width: "50%" }}>
              <img
                src={logoImage}
                alt=""
                className="img-fluid"
                style={{ float: "left" }}
              />
            </a>
            {/* <p>Volup amet magna clita tempor. Tempor sea eos vero ipsum. Lorem lorem sit sed elitr sed kasd et</p> */}
            {/* <h5 className="font-weight-semi-bold mb-2 text-white">Opening Hours:</h5>
            <p className="mb-1">Mon – Sat, 8AM – 5PM</p> */}
            {/* <p>Sunday: Closed</p> */}
          </div>
          <div className="col-lg-4 col-md-6 mb-5 ">
            <h4 className="font-weight-semi-bold text-white mb-4">
              Get In Touch
            </h4>
            <p className="text-white">
              <i className="fa fa-map-marker-alt text-white mr-2"></i><Link to="https://www.google.com/maps/place/28%C2%B037'36.0%22N+77%C2%B022'40.6%22E/@28.6266772,77.3753575,17z/data=!3m1!4b1!4m4!3m3!8m2!3d28.6266725!4d77.3779324?entry=ttu" className="text-white" target="_blank">Noida Sector 63, 201301</Link>
            </p>
            <p className="text-white">
              <i className="fa fa-phone-alt text-white mr-1"></i>
              <Link to="tel:+91-8006305606" className="text-white">+91-8006305606</Link>
            </p>
            <p className="text-white">
              <i className="fa fa-envelope  mr-2"></i>
              <Link to="mailto:helpdesk@safaixyz.com" className="text-white">helpdesk@safaixyz.com</Link>
            </p>
            <div className="d-flex justify-content-start mt-4">
              {/* <Link className="btn btn-light btn-social mr-2" to="#" aria-label="Twitter">
                    <i className="fab fa-twitter"></i>
                  </Link> */}
              <Link
                className="btn btn-light btn-social mr-2"
                to="https://facebook.com/safaixyz"
                aria-label="Facebook"
                target="_blank"
              >
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link
                className="btn btn-light btn-social mr-2"
                to="https://www.youtube.com/@Safaixyz"
                target="_blank"
                aria-label="Youtube"
              >
                <i className="fab fa-youtube"></i>
              </Link>
              <Link
                className="btn btn-light btn-social"
                to="https://www.instagram.com/safaixyz?igsh=MWltajU4NTMxdDJvdw=="
                aria-label="Instagram"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-5">
            <h4 className="font-weight-semi-bold text-white mb-4">
              Quick Links
            </h4>
            <div className="d-flex flex-column justify-content-start">
              <Link className=" mb-2" to="/">
                <i className="fa fa-angle-right mr-2"></i>Home
              </Link>
              <Link className=" mb-2" to="/about">
                <i className="fa fa-angle-right mr-2"></i>About Us
              </Link>
              <Link className=" mb-2" to="/service">
                <i className="fa fa-angle-right mr-2"></i>Our Services
              </Link>
              <Link className=" mb-2" to="/projects">
                <i className="fa fa-angle-right mr-2"></i>Our Projects
              </Link>
              <Link className="" to="/contact">
                <i className="fa fa-angle-right mr-2"></i>Contact Us
              </Link>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6 mb-5">
            <h4 className="font-weight-semi-bold text-white mb-4">Newsletter</h4>
            <p>Rebum labore lorem dolores kasd est, et ipsum amet et at kasd, ipsum sea tempor magna tempor. Accu kasd sed ea duo ipsum.</p>
            <div className="w-100">
              <div className="input-group">
                <input type="text" className="form-control border-0" style={{ padding: "25px" }} placeholder="Your Email" />
                <div className="input-group-append">
                  <button className="btn btn-primary px-4">Sign Up</button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Footer;

import React, { useState, useEffect } from "react";
import { APIEndtPoint } from "./Apis";
import Tabs from "./Tabs";

function FullHouseCategory({ addToCart, isProductInCart }) {
  const [dataFullHouseCategory, setFullHouseCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    const fetchFullHouseCategoryData = async () => {
      const response = await fetch(APIEndtPoint + "categories/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ category_type: "full_house_services" }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Full House Cleaning Category Response:", result);
        setFullHouseCategoryData(result);
        setSelectedCategory(result[0]);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    };
    fetchFullHouseCategoryData();
  }, []);

  const handleClick = (category) => {
    console.log("Handling click for category:", category);
    setSelectedCategory(category);
  };
  return (
    <div>
      <div class="popular-service-bg">
        <div className="row col-lg-12">
          <h1 class="section-title-1 mb-4 px-3">Full House Cleaning</h1>
        </div>
        <ul className="tab-list border">
          {dataFullHouseCategory.map((category, index) => (
            <li
              key={category.id}
              className={`tabs ${selectedCategory === category ? "active-tabs" : ""}`}
              onClick={() => setSelectedCategory(category)}
            >
              {category.name}
            </li>
          ))}
        </ul>
        {selectedCategory && (
          <Tabs
            data={[selectedCategory]}
            addToCart={addToCart}
            isProductInCart={isProductInCart}
          ></Tabs>
        )}
      </div>
    </div>
  )
}

export default FullHouseCategory
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { APIEndtPoint } from './Apis';

function SignIn() {
  const [formData, setFormData] = useState({
    phone_number: '',
    password: ''
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { phone_number, password } = formData;

    try {
      const response = await fetch(APIEndtPoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ phone_number, password })
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData && errorData.errors) {
          const newErrors = {};
          errorData.errors.forEach((err) => {
            if (err.toLowerCase().includes("phone")) {
              newErrors.phone_number = err;
            } else if (err.toLowerCase().includes("password")) {
              newErrors.password = err;
            }
          });
          setErrors(newErrors);
        } else {
          throw new Error('Failed to sign in');
        }
      } else {
        const result = await response.json();
        console.log('Success:', result);
        localStorage.setItem('userToken', result.token);
        // alert('Sign-in successful!');
        setFormData({ phone_number: '', password: '' });
        setErrors({});
        navigate('/checkout');
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
      setErrors({ form: "Invalid Phone Number and password" });
    }
  };

  return (
    <>
      <style jsx>{`
        form {
          max-width: 400px;
          margin: 200px auto;
          padding: 20px;
          border: 1px solid #ccc;
          border-radius: 8px;
          background-color: #f9f9f9;
        }
        h2 {
          text-align: center;
          margin-bottom: 20px;
        }
        label {
          display: block;
          margin-top: 10px;
          margin-bottom: 5px;
        }
        input {
          width: 100%;
          padding: 8px;
          margin-bottom: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        button {
          width: 100%;
          padding: 10px;
          background-color: #007BFF;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
        }
        button:hover {
          background-color: #0056b3;
        }
        .error {
          color: red;
        }
        .signup-link {
          display: block;
          text-align: center;
          margin-top: 10px;
        }
      `}</style>
      <form onSubmit={handleSubmit}>
        <h2>Sign In</h2>
        {/* {console.log(localStorage.getItem('userToken'))} */}
        <label htmlFor="phone_number">Phone Number:</label>
        <input type="number" id="phone_number" name="phone_number" value={formData.phone_number} onChange={handleChange} placeholder="Phone Number" required />

        <label htmlFor="password">Password:</label>
        <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password" required />

        {errors.form && <div className="error mb-2">{errors.form}</div>}
        <button type="submit">Sign In</button>
        <Link to="/signup" className="signup-link">Don't have an account? Sign up here!</Link>
      </form>
    </>
  );
}

export default SignIn;

import React from "react";
import bannerImage_1 from "../img/Banner/Banner-1.png";
import bannerImage_2 from "../img/Banner/Banner-2.png";
import bannerImage_3 from "../img/Banner/Banner-3.png";

function Carousel() {
  return (
    <>
      <div className="container-fluid">
        <div className="">
          <div className="owl-carousel team-carousel position-relative">
            <div className="text-black">
              <img className="img-fluid" src={bannerImage_1} alt="Image" />
            </div>
            <div className="text-black">
              <img className="img-fluid" src={bannerImage_2} alt="Image" />
            </div>
            <div className="text-black">
              <img className="img-fluid" src={bannerImage_3} alt="Image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Carousel;

import React from 'react'
import  blogImage1 from '../img/blog-1.jpg'
import  blogImage2 from '../img/blog-2.jpg'
import  blogImage3 from '../img/blog-3.jpg'
import Footer from './Footer'
import Header from './Header'
import { Link } from 'react-router-dom'

function Blog() {
  return (
    <>
      {/* Header start */}
      <Header data="blog"/>
      {/* Header end */}
      {/* Page Header Start  */}
      {/* <div className="container-fluid bg-primary py-5 mb-5">
          <div className="container py-5">
              <div className="row align-items-center py-4">
                  <div className="col-md-6 text-center text-md-left">
                      <h1 className="display-4 mb-4 mb-md-0 text-secondary-1 text-uppercase">Blog</h1>
                  </div>
                  <div className="col-md-6 text-center text-md-right">
                      <div className="d-inline-flex align-items-center">
                          <Link className="btn btn-sm btn-outline-light" to="/">Home</Link>
                          <i className="fas fa-angle-double-right text-light mx-2"></i>
                          <Link className="btn btn-sm btn-outline-light disabled" to="">Blog</Link>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
      <div className="container-fluid mb-5">
        <div className="bg-primary">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="display-4 text-center mb-4 text-secondary-1 text-uppercase text-white">
                Blog
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-sm btn-outline-light" to="/">
                  Home
                </Link>
                <i className="fas fa-angle-double-right text-light mx-2"></i>
                <Link
                  className="btn btn-sm btn-outline-light disabled"
                  to="/blog"
                >
                  Blog
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Page Header End  */}
      {/* Blog Start  */}
      <div className="container-fluid">
          <div className="container">
              <div className="row align-items-end mb-4">
                  <div className="col-lg-6">
                      {/* <h6 className="text-secondary font-weight-semi-bold text-uppercase mb-3">Latest Blog</h6> */}
                      <h1 className="section-title mb-3">Latest Articles From Our Blog Post</h1>
                  </div>
                  <div className="col-lg-6">
                      <h4 className="font-weight-normal text-muted mb-3">Eirmod kasd duo eos et magna, diam dolore stet sea clita sit ea erat lorem. Ipsum eos ipsum magna lorem stet</h4>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-4 col-md-6 mb-5">
                      <div className="position-relative mb-4">
                          <img className="img-fluid rounded w-100" src={blogImage1} alt=""/>
                          <div className="blog-date">
                              <h4 className="font-weight-bold mb-n1">01</h4>
                              <small className="text-white text-uppercase">Jan</small>
                          </div>
                      </div>
                      <div className="d-flex mb-2">
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Admin</Link>
                          <span className="text-primary px-2">|</span>
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Cleaning</Link>
                      </div>
                      <h5 className="font-weight-medium mb-2">Rebum lorem eos ipsum diam</h5>
                      <p className="mb-4">Dolor justo sea kasd lorem clita justo no diam amet. Kasd magna dolor amet</p>
                      <Link className="btn btn-sm btn-primary py-2" to="">Read More</Link>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                      <div className="position-relative mb-4">
                          <img className="img-fluid rounded w-100" src={blogImage2} alt=""/>
                          <div className="blog-date">
                              <h4 className="font-weight-bold mb-n1">01</h4>
                              <small className="text-white text-uppercase">Jan</small>
                          </div>
                      </div>
                      <div className="d-flex mb-2">
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Admin</Link>
                          <span className="text-primary px-2">|</span>
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Cleaning</Link>
                      </div>
                      <h5 className="font-weight-medium mb-2">Rebum lorem eos ipsum diam</h5>
                      <p className="mb-4">Dolor justo sea kasd lorem clita justo no diam amet. Kasd magna dolor amet</p>
                      <Link className="btn btn-sm btn-primary py-2" to="">Read More</Link>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                      <div className="position-relative mb-4">
                          <img className="img-fluid rounded w-100" src={blogImage3} alt=""/>
                          <div className="blog-date">
                              <h4 className="font-weight-bold mb-n1">01</h4>
                              <small className="text-white text-uppercase">Jan</small>
                          </div>
                      </div>
                      <div className="d-flex mb-2">
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Admin</Link>
                          <span className="text-primary px-2">|</span>
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Cleaning</Link>
                      </div>
                      <h5 className="font-weight-medium mb-2">Rebum lorem eos ipsum diam</h5>
                      <p className="mb-4">Dolor justo sea kasd lorem clita justo no diam amet. Kasd magna dolor amet</p>
                      <Link className="btn btn-sm btn-primary py-2" to="">Read More</Link>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                      <div className="position-relative mb-4">
                          <img className="img-fluid rounded w-100" src={blogImage2} alt=""/>
                          <div className="blog-date">
                              <h4 className="font-weight-bold mb-n1">01</h4>
                              <small className="text-white text-uppercase">Jan</small>
                          </div>
                      </div>
                      <div className="d-flex mb-2">
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Admin</Link>
                          <span className="text-primary px-2">|</span>
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Cleaning</Link>
                      </div>
                      <h5 className="font-weight-medium mb-2">Rebum lorem eos ipsum diam</h5>
                      <p className="mb-4">Dolor justo sea kasd lorem clita justo no diam amet. Kasd magna dolor amet</p>
                      <Link className="btn btn-sm btn-primary py-2" to="">Read More</Link>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                      <div className="position-relative mb-4">
                          <img className="img-fluid rounded w-100" src={blogImage3} alt=""/>
                          <div className="blog-date">
                              <h4 className="font-weight-bold mb-n1">01</h4>
                              <small className="text-white text-uppercase">Jan</small>
                          </div>
                      </div>
                      <div className="d-flex mb-2">
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Admin</Link>
                          <span className="text-primary px-2">|</span>
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Cleaning</Link>
                      </div>
                      <h5 className="font-weight-medium mb-2">Rebum lorem eos ipsum diam</h5>
                      <p className="mb-4">Dolor justo sea kasd lorem clita justo no diam amet. Kasd magna dolor amet</p>
                      <Link className="btn btn-sm btn-primary py-2" to="">Read More</Link>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-5">
                      <div className="position-relative mb-4">
                          <img className="img-fluid rounded w-100" src={blogImage1} alt=""/>
                          <div className="blog-date">
                              <h4 className="font-weight-bold mb-n1">01</h4>
                              <small className="text-white text-uppercase">Jan</small>
                          </div>
                      </div>
                      <div className="d-flex mb-2">
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Admin</Link>
                          <span className="text-primary px-2">|</span>
                          <Link className="text-secondary-1 text-uppercase font-weight-medium" to="">Cleaning</Link>
                      </div>
                      <h5 className="font-weight-medium mb-2">Rebum lorem eos ipsum diam</h5>
                      <p className="mb-4">Dolor justo sea kasd lorem clita justo no diam amet. Kasd magna dolor amet</p>
                      <Link className="btn btn-sm btn-primary py-2" to="">Read More</Link>
                  </div>
                  <div className="col-12">
                      <nav aria-label="Page navigation">
                        <ul className="pagination pagination-lg justify-content-center mb-0">
                          <li className="page-item disabled">
                            <Link className="page-link" to="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span className="sr-only">Previous</span>
                            </Link>
                          </li>
                          <li className="page-item active"><Link className="page-link" to="#">1</Link></li>
                          <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                          <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                          <li className="page-item">
                            <Link className="page-link" to="#" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span className="sr-only">Next</span>
                            </Link>
                          </li>
                        </ul>
                      </nav>
                  </div>
              </div>
          </div>
      </div>
      {/* Blog End  */}
      <Footer />
    </>
  )
}

export default Blog
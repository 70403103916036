import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

function Carees() {
  return (
    <>
      <Header data="careers" />
      {/* <div className="container-fluid mb-5">
        <div className="bg-primary">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="display-4 text-center mb-4-1 text-uppercase text-white">
                careers
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-sm btn-outline-light" to="/">
                  Home
                </Link>
                <i className="fas fa-angle-double-right text-light mx-2"></i>
                <Link
                  className="btn btn-sm btn-outline-light disabled"
                  to="/careers"
                >
                  careers
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container-fluid">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-12">
              <h1 className="section-title mb-3">Join Our Team at SafaiXYZ!</h1>
            </div>
            <div className="col-lg-12">
              <p className="mb-3">
                At SafaiXYZ, we are committed to creating clean, healthy, and
                vibrant spaces for our clients. We are always on the lookout for
                dedicated, hardworking individuals to join our growing team. If
                you have a passion for cleanliness and a desire to make a
                difference, explore our career opportunities below and become a
                part of the SafaiXYZ family.
              </p>
            </div>
          </div>
          <h1 className="section-title mb-3">Current Openings:</h1>
          <div className="row">
            <div className="col-lg-6 col-md-6 mb-5">
              <h5 className="section-title font-weight-medium">Home Cleaning Specialist</h5>
              <div className="">
                <span>
                  <p>
                    As a Home Cleaning Specialist, you will be responsible for
                    providing top-notch cleaning services to our residential
                    clients. Your duties will include:
                  </p>
                </span>
                <li className="job-list">
                  Dusting, vacuuming, and mopping floors
                </li>
                <li className="job-list">Cleaning bathrooms and kitchens</li>
                <li className="job-list">Tidying up living spaces</li>
                <li className="job-list">
                  Changing bed linens and doing laundry
                </li>
                <li className="job-list">
                  Using eco-friendly cleaning products
                </li>
              </div>
              <div className="mt-2">
                <span>
                  <b className="section-title">Requirements:</b>
                </span>
                <li className="job-list">
                  Prior experience in home cleaning preferred
                </li>
                <li className="job-list">
                  Attention to detail and strong organizational skills
                </li>
                <li className="job-list">
                  Ability to work independently and manage time effectively
                </li>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mb-5">
              <h5 className="section-title font-weight-medium">
                Professional Cleaners
              </h5>
              <div className="">
                <span>
                 <p> As a Professional Cleaner at Safai, you will be responsible
                  for deep cleaning services for various types of properties.
                  Duties include:</p>
                </span>
                <li className="job-list">
                  Performing deep cleaning tasks in commercial and residential
                  spaces
                </li>
                <li className="job-list">
                  Using specialized cleaning equipment and products
                </li>
                <li className="job-list">
                  Following safety protocols and procedures
                </li>
                <li className="job-list">
                  Ensuring client satisfaction through high-quality service
                </li>
              </div>
              <div className="mt-2">
                <span>
                  <b className="section-title">Requirements:</b>
                </span>
                <li className="job-list">
                  Proven experience as a cleaner or janitor
                </li>
                <li className="job-list">
                  Knowledge of cleaning chemicals and supplies
                </li>
                <li className="job-list">
                  Ability to handle heavy equipment and machinery
                </li>
                <li className="job-list">Detail-oriented and meticulous</li>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mb-5">
              <h5 className=" section-title font-weight-medium">Office Boy</h5>
              <div className="">
                <span>
                  <p>
                    Our Office Boys play a crucial role in maintaining the
                    cleanliness and organization of our clients' offices.
                    Responsibilities include:
                  </p>
                </span>
                <li className="job-list">
                  Keeping office spaces clean and tidy
                </li>
                <li className="job-list">
                  Assisting with basic administrative tasks
                </li>
                <li className="job-list">
                  Running errands and handling deliveries
                </li>
                <li className="job-list">Supporting office staff as needed</li>
              </div>
              <div className="mt-2">
                <span>
                  <b className="section-title">Requirements:</b>
                </span>
                <li className="job-list">High school diploma or equivalent</li>
                <li className="job-list">
                  Good communication and interpersonal skills
                </li>
                <li className="job-list">Reliable and trustworthy</li>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mb-5">
              <h5 className="section-title font-weight-medium">Sweeper</h5>
              <div className="">
                <span>
                  <p>
                    We are looking for dedicated Sweepers to keep our client's
                    premises spotless. Your job will include:
                  </p>
                </span>
                <li className="job-list">Sweeping and mopping floors</li>
                <li className="job-list">Cleaning outdoor areas</li>
                <li className="job-list">
                  Removing trash and disposing of it properly
                </li>
                <li className="job-list">
                  Maintaining cleanliness in hallways and common areas
                </li>
              </div>
              <div className="mt-2">
                <span>
                  <b className="section-title">Requirements:</b>
                </span>
                <li className="job-list">
                  Experience in a similar role preferred
                </li>
                <li className="job-list">
                  Physical stamina and ability to work on your feet for long
                  periods
                </li>
                <li className="job-list">Strong work ethic</li>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <h1 className="section-title mb-3">How to Apply</h1>
            <p>
              If you are interested in any of the above positions, please submit
              your resume and a brief cover letter to
              <Link to="mailto:helpdesk@safaixyz.com" className="text-white">
                &nbsp;helpdesk@safaixyz.com
              </Link>{" "}
              or call us on
              <br></br>
              <Link to="tel:+91-8006305606" className="text-white">&nbsp;+91-8006305606</Link> Be sure
              to mention the position you are applying for in the subject line.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Carees;

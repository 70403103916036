// import React, { useEffect, useState } from 'react';
// import { APIEndtPoint } from "./Apis";

// const CurrentVendorServices = () => {
//   const [vendorServices, setVendorServices] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchVendorServices = async () => {
//       try {
//         const response = await fetch(
//           APIEndtPoint + 'v1/vendors/vendor_services',
//           {
//             method: "GET",
//             headers: {
//               Authorization: localStorage.getItem("userToken"),
//               "Content-Type": "application/json",
//             },
//           }
//         );
//         const data = await response.json();
//       {console.log("***************jjjjjj", localStorage.getItem("userToken"))}

//         setVendorServices(data);
//         setLoading(false);
//       } catch (err) {
//         console.error('Error fetching vendor services:', err);
//         setError('Failed to load services');
//         setLoading(false);
//       }
//     };

//     fetchVendorServices();
//   }, []);

//   if (loading) return <div>Loading services...</div>;
//   if (error) return <div>{error}</div>;

//   return (
//     <div>
//       <div className="vendor-services-list">
//       {console.log("***************", vendorServices )}
//         {Array.isArray(vendorServices) && vendorServices.map((serviceData) => {
//           console.log('kkkkkkkkkkkk', serviceData)
//           const { id, service, location } = serviceData;
//           return (
//             <div key={id} className="service-card">
//               {/* <img src={service.images?.url} alt={service.title} className="service-image" /> */}
//               {/* <img 
//   src={service.images?.url} 
//   alt={service.title} 
//   className="service-image" 
//   style={{ width: '150px', height: 'auto', objectFit: 'cover', borderRadius: '6px' }} 
// /> */}
//               <h2>{service.title}</h2>
//               {/* <p>Price: ₹{service.price}</p> */}
//               <p className="price">Price: ₹{service.price}</p>
//               {/* <p dangerouslySetInnerHTML={{ __html: service.description }} /> */}
//               <div className="service-location">
//                 <h3>Location:</h3>
//                 <p>{location.address1}</p>
//                 <p>{location.locality}, {location.state}, {location.pin_code}</p>
//               </div>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default CurrentVendorServices;
import React, { useEffect, useState } from 'react';
import { APIEndtPoint } from "./Apis";

const CurrentVendorServices = () => {
  const [vendorServices, setVendorServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVendorServices = async () => {
      try {
        const response = await fetch(
          APIEndtPoint + 'v1/vendors/vendor_services',
          {
            method: "GET",
            headers: {
              Authorization: localStorage.getItem("userToken"),
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setVendorServices(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching vendor services:', err);
        setError('Failed to load services');
        setLoading(false);
      }
    };

    fetchVendorServices();
  }, []);

  const handleEdit = () => {
    console.log('Editing the whole section');
    // Implement logic to edit the entire section (e.g., open modal or navigate to another page)
  };

  const handleDelete = () => {
    console.log('Deleting the whole section');
    // Implement logic to delete the entire section (e.g., reset state or send a delete request to API)
    setVendorServices([]); // Example of clearing the list of services
  };

  if (loading) return <div>Loading services...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="vendor-services-list">
        {vendorServices.length === 0 ? (
          <p>No services available</p>
        ) : (
          vendorServices.map((serviceData) => {
            const { id, service, location } = serviceData;
            return (
              <div key={id} className="service-card">
                <h2>{service.title}</h2>
                <p className="price">Price: ₹{service.price}</p>
                <div className="service-location">
                  <h3>Location:</h3>
                  <p>{location.address1}</p>
                  <p>{location.locality}, {location.state}, {location.pin_code}</p>
                </div>
              </div>
            );
          })
        )}
      </div>

      <div className="action-buttons">
        <button onClick={handleEdit} className="edit-btn">Edit Section</button>
        <button onClick={handleDelete} className="delete-btn">Delete Section</button>
      </div>
    </div>
  );
};

export default CurrentVendorServices;

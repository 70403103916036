import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicDetails from "./BasicDetails";
import Header from "./Header";
import Footer from "./Footer"

function CartDetailsForMobile() {
  const navigate = useNavigate();
  const [subtotal, setSubtotal] = useState(() =>
    parseFloat(localStorage.getItem("subtotal") || 0)
  );
  const [cartItems, setCartItems] = useState(
    () => JSON.parse(localStorage.getItem("cart")) || []
  );

  const [showBasicDetails, setShowBasicDetails] = useState(false);
  const removeFromCart = (productId) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== productId);
    const removedItem = cartItems.find((item) => item.id === productId);
    const newSubtotal =
      subtotal - parseFloat(removedItem.price * removedItem.quantity);

    setCartItems(updatedCartItems);
    setSubtotal(newSubtotal);
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    localStorage.setItem("subtotal", newSubtotal.toString());
  };

  const handleShowBasicDetails = () => {
    if (cartItems.length > 0) {
      setShowBasicDetails(true);
    }
  };

  return (
    <>
      <Header data={"cart"} />
      <div className="container-fluid">
        <div className="container">
          <div className="row cart-totals">
            <div className="col-lg-12">
              <h3 className="">Estimated Price</h3>
              <hr />
            </div>
            {cartItems.length > 0 ? (
              <>
                <div className="row col-lg-12">
                  {cartItems.map((item) => (
                    <>
                      <div className="col-6">
                        <li>{item.title}</li>
                      </div>
                      {/* <div className="col-4">₹{item.price}</div> */}
                      <div className="col-4">{item.quantity}×{item.price}=₹{item.quantity*item.price}</div>
                      <div className="col-2 mb-1">
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => removeFromCart(item.id)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </div>
                    </>
                  ))}
                </div>
                <div className="row col-lg-12">
                  <div className="col-6"></div>
                  <div className="col-4 mb-1">
                    {" "}
                    <b>Total Price</b>
                    <div className="">₹{subtotal.toFixed(2)}</div>
                  </div>
                  <div className="col-2"></div>
                </div>
                {!showBasicDetails && (
                  <div className="btn-cart-totals mt-3 d-flex justify-content-between">
                    <button
                      onClick={handleShowBasicDetails}
                      className="btn btn-primary"
                      disabled={cartItems.length === 0}
                    >
                      Add Basic Details
                    </button>
                    <button
                      onClick={() => navigate(-1)}
                      className="btn btn-primary ms-2 ml-2"
                    >
                      Back To Preview Page
                    </button>
                  </div>
                )}
                {showBasicDetails && <BasicDetails />}
              </>
            ) : (
              <div className="col-lg-12">No Any item found in cart</div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CartDetailsForMobile;

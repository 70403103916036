import React from "react";
import Header from "./Header";
import portfolioImage1 from "../img/Projects-Images/Gaur-city-Project-Image.png";
import portfolioImage2 from "../img/Projects-Images/Mahagun-Mywoods.png";
import portfolioImage3 from "../img/Projects-Images/Supertech-Eco-village-1.png";
import portfolioImage4 from "../img/Projects-Images/Spring-Meadows.png";
import portfolioImage5 from "../img/Projects-Images/French-Apartments.png";
import portfolioImage6 from "../img/Projects-Images/ats-greens-village.jpg"
import Footer from "./Footer";

function Projects() {
  return (
    <>
      <Header data={"projects"} />

      {/* <div className="container-fluid mb-5">
        <div className="bg-primary">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="display-4 text-center mb-4 text-secondary-1 text-uppercase text-white">
                Projects
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-sm btn-outline-light" to="/">
                  Home
                </Link>
                <i className="fas fa-angle-double-right text-light mx-2"></i>
                <Link
                  className="btn btn-sm btn-outline-light disabled"
                  to="/project"
                >
                  Projects
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div class="container-fluid">
        <div class="container">
          <div class="row align-items-end mb-4">
            <div class="col-lg-12">
              <h1 class="section-title mb-3 text-center">
                We serve more than 500+ happy customers in the cities
              </h1>
            </div>
          </div>
          <h3 class="section-title mb-3 text-center">
            Our Top Cities for provided services:-
          </h3>
          <div class="row m-0 portfolio-container">
            <div class="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item first">
              <div class="position-relative overflow-hidden">
                <div class="portfolio-img">
                  <img class="img-fluid w-100" src={portfolioImage1} alt="" />
                </div>
                <div class="portfolio-text bg-primary">
                  <h4 class="font-weight-bold mb-4">Gaur City</h4>
                  <h6 className="m-3">One of the largest residential complexes in Noida Extension, Gaur City benefits from our professional cleaning services.</h6>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item second">
              <div class="position-relative overflow-hidden">
                <div class="portfolio-img">
                  <img class="img-fluid w-100" src={portfolioImage2} alt="" />
                </div>
                <div class="portfolio-text bg-primary">
                  <h4 class="font-weight-bold mb-4">Mahagun Mywoods</h4>
                  <h6 className="m-3">This premium society enjoys our consistent and reliable cleaning services.</h6>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item third">
              <div class="position-relative overflow-hidden">
                <div class="portfolio-img">
                  <img class="img-fluid w-100" src={portfolioImage3} alt="" />
                </div>
                <div class="portfolio-text bg-primary">
                  <h4 class="font-weight-bold mb-4">SuperTech Eco Village-1</h4>
                  <h6 className="m-3">Nestled in Noida Extension, SuperTech Eco Village-1 offers a blend of modern amenities and eco-friendly living, featuring spacious apartments, lush green landscapes, and a vibrant community.</h6>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item first">
              <div class="position-relative overflow-hidden">
                <div class="portfolio-img">
                  <img class="img-fluid w-100" src={portfolioImage4} alt="" />
                </div>
                <div class="portfolio-text bg-primary">
                  <h4 class="font-weight-bold mb-4">Spring Meadows</h4>
                  <h6 className="m-3">Located in Greater Noida West, Spring Meadows combines contemporary architecture with affordable luxury, providing residents with stylish homes, ample recreational facilities, and excellent connectivity to key areas.</h6>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item second">
              <div class="position-relative overflow-hidden">
                <div class="portfolio-img">
                  <img class="img-fluid w-100" src={portfolioImage5} alt="" />
                </div>
                <div class="portfolio-text bg-primary">
                  <h4 class="font-weight-bold mb-4">French Apartments</h4>
                  <h6 className="m-3">We provide top-notch cleaning solutions to maintain the high standards of this modern residential society.</h6>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 p-0 portfolio-item third">
              <div class="position-relative overflow-hidden">
                <div class="portfolio-img">
                  <img class="img-fluid w-100" src={portfolioImage6} alt="" />
                </div>
                <div class="portfolio-text bg-primary">
                  <h4 class="font-weight-bold mb-4">ATS Greens Village</h4>
                  <h6 className="m-3">Known for its luxurious living spaces, ATS Greens Village relies on Safai for maintaining their pristine environment.</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Projects;

import React from "react";
import { Link } from "react-router-dom";
// import blog1Image from "../img/blog-1.jpg";
// import blog2Image from "../img/blog-2.jpg";
// import blog3Image from "../img/blog-3.jpg";
// import testimonialImage from "../img/testimonial.jpg";
// import testimonial1Image from "../img/testimonial-1.jpg";
// import testimonial2Image from "../img/testimonial-2.jpg";
// import testimonial3Image from "../img/testimonial-3.jpg";
// import WashRoomCleaningVideo from 'https://www.instagram.com/reel/C7L_-NxvTvm/?utm_source=ig_web_button_share_sheet';
import Footer from "./Footer";
import Header from "./Header";

function Service() {
  return (
    <>
      {/* Header start */}
      <Header data="service" />
      {/* Header end */}

      {/* Page Header Start  */}
      {/* <div className="container-fluid py-5 mb-5">
          <div className="container py-5">
              <div className="row align-items-center py-4">
                  <div className="col-md-6 text-center text-md-left">
                      <h1 className="display-4 mb-4 mb-md-0 text-secondary-1 text-uppercase">Service</h1>
                  </div>
                  <div className="col-md-6 text-center text-md-right">
                      <div className="d-inline-flex align-items-center">
                          <Link className="btn btn-sm btn-outline-light" to="/">Home</Link>
                          <i className="fas fa-angle-double-right text-light mx-2"></i>
                          <Link className="btn btn-sm btn-outline-light disabled" to="/service">Service</Link>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
      {/* <div className="container-fluid mb-5">
        <div className=">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="display-4 text-center mb-4 text-secondary-1 text-uppercase text-white">
                Service
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-sm btn-outline-light" to="/">
                  Home
                </Link>
                <i className="fas fa-angle-double-right text-light mx-2"></i>
                <Link
                  className="btn btn-sm btn-outline-light disabled"
                  to="/service"
                >
                  Service
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Page Header End  */}

      {/* Services Start  */}
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* <h6 className="text-secondary font-weight-semi-bold text-uppercase mb-3">
                Our Services
              </h6> */}
              <h1 className="section-title">
                Awesome Cleaning Services For You
              </h1>
              <p>
                Welcome to SafaiXYZ, your one-stop destination for comprehensive
                home cleaning solutions. We pride ourselves on delivering
                top-notch services tailored to meet your every cleaning need.
                From washroom cleaning to deep house cleaning, kitchen cleaning,
                and a plethora of attractive mini services, SafaiXYZ has you
                covered.
                {/* <div className="col-lg-12 pt-5 pt-lg-0 mt-5">
                  <div className="owl-carousel service-carousel position-relative">
                    <div className="d-flex flex-column align-items-center text-center bg-light rounded overflow-hidden pt-4">
                      <div className="icon-box bg-light text-secondary shadow mt-2 mb-4">
                        <i className="fa fa-2x fa-hotel"></i>
                      </div>
                      <h5 className="font-weight-bold mb-4 px-4">
                        Home Cleaning
                      </h5>
                      <img src={blog1Image} alt="" />
                    </div>
                    <div className="d-flex flex-column align-items-center text-center bg-light rounded overflow-hidden pt-4">
                      <div className="icon-box bg-light text-secondary shadow mt-2 mb-4">
                        <i className="fa fa-2x fa-city"></i>
                      </div>
                      <h5 className="font-weight-bold mb-4 px-4">
                        Window Cleaning
                      </h5>
                      <img src={blog2Image} alt="" />
                    </div>
                    <div className="d-flex flex-column align-items-center text-center bg-light rounded overflow-hidden pt-4">
                      <div className="icon-box bg-light text-secondary shadow mt-2 mb-4">
                        <i className="fa fa-2x fa-spa"></i>
                      </div>
                      <h5 className="font-weight-bold mb-4 px-4">
                        Carpet Cleaning
                      </h5>
                      <img src={blog3Image} alt="" />
                    </div>
                  </div>
                </div> */}
                <h5 class="section-title mt-5">
                  {/* Our core services include: */}
                </h5>
                <div className="row contact-info mt-3">
                  <div className="col-lg-4 bg-2-color bg-multi-color">
                    <div className="contact-info-item d-flex align-items-center justify-content-center text-white py-4 py-lg-0">
                      {/* <i className="fa fa-3x fa-toilet text-secondary mr-4"></i> */}
                      <div className="">
                        <Link className="mb-2" to='/buynow?category_id=6'><h5 className="mb-2">Washroom Cleaning</h5></Link>
                        {/* <div className="mb-2" style={{ width: '300px', height: '130px' }}>
                        <iframe 
                          src="https://www.instagram.com/reel/C7L_-NxvTvm/embed"
                          width="300" 
                          height="130" 
                          frameBorder="0" 
                          allowFullScreen 
                          title="Washroom Cleaning Video"
                        ></iframe>
                      </div> */}
                        {/* <p className="m-0">Noida Sector 63, 201301</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 bg-2-color bg-multi-color">
                    <div className="contact-info-item d-flex align-items-center justify-content-center bg-secondary py-4 py-lg-0">
                      {/* <i className="fa fa-3x fa-home text-primary mr-4"></i> */}
                      <div className="">
                        <Link className="mb-2" to='/buynow?category_id=4'><h5 className="mb-2">Deep House Cleaning</h5></Link>
                        {/* <p className="m-0">helpdesk@safaixyz.com</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 bg-2-color bg-multi-color">
                    <div className="contact-info-item d-flex align-items-center justify-content-center text-white py-4 py-lg-0">
                      {/* <i className="fa fa-3x fa-cutlery text-secondary mr-4"></i> */}
                      <div className="">
                        <Link className="mb-2" to='/buynow?category_id=5'><h5 className="mb-2">Kitchen Cleaning</h5></Link>
                        {/* <p className="m-0">+91-8006305606</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row contact-info mt-3 bg-multi-color">
                  <div className="col-lg-4 bg-2-color">
                    <div className="contact-info-item d-flex align-items-center justify-content-center text-white py-4 py-lg-0"> */}
                {/* <i className="fa fa-3x fa-map-marker-alt text-secondary mr-4"></i> */}
                {/* <div className="">
                        <h5 className="mb-2">Fan Cleaning</h5> */}
                {/* <p className="m-0">Noida Sector 63, 201301</p> */}
                {/* </div>
                    </div>
                  </div>
                  <div className="col-lg-4 bg-2-color">
                    <div className="contact-info-item d-flex align-items-center justify-content-center bg-secondary py-4 py-lg-0"> */}
                {/* <i className="fa fa-3x fa-envelope-open text-primary mr-4"></i> */}
                {/* <div className="">
                        <h5 className="mb-2">Ghar Ke Jale Cleaning</h5> */}
                {/* <p className="m-0">helpdesk@safaixyz.com</p> */}
                {/* </div>
                    </div>
                  </div>
                  <div className="col-lg-4 bg-2-color">
                    <div className="contact-info-item d-flex align-items-center justify-content-center text-white py-4 py-lg-0"> */}
                {/* <i className="fa fa-3x fa-phone-alt text-secondary mr-4"></i> */}
                {/* <div className="">
                        <h5 className="mb-2">Fridge Cleaning</h5> */}
                {/* <p className="m-0">+91-8006305606</p> */}
                {/* </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="row contact-info mt-3 bg-multi-color">
                  <div className="col-lg-4 bg-2-color">
                    <div className="contact-info-item d-flex align-items-center justify-content-center text-white py-4 py-lg-0"> */}
                {/* <i className="fa fa-3x fa-map-marker-alt text-secondary mr-4"></i> */}
                {/* <div className="">
                        <h5 className="mb-2">Sofa Cleaning</h5> */}
                {/* <p className="m-0">Noida Sector 63, 201301</p> */}
                {/* </div>
                    </div>
                  </div>
                  <div className="col-lg-4 bg-2-color">
                    <div className="contact-info-item d-flex align-items-center justify-content-center bg-secondary py-4 py-lg-0"> */}
                {/* <i className="fa fa-3x fa-envelope-open text-primary mr-4"></i> */}
                {/* <div className="">
                        <h5 className="mb-2">AC Cleaning</h5> */}
                {/* <p className="m-0">helpdesk@safaixyz.com</p> */}
                {/* </div>
                    </div>
                  </div>
                  <div className="col-lg-4 bg-2-color">
                    <div className="contact-info-item d-flex align-items-center justify-content-center text-white py-4 py-lg-0"> */}
                {/* <i className="fa fa-3x fa-phone-alt text-secondary mr-4"></i> */}
                {/* <div className="">
                        <h5 className="mb-2">Window Cleaning</h5> */}
                {/* <p className="m-0">+91-8006305606</p> */}
                {/* </div>
                    </div>
                  </div>
                </div> */}
                <div className="row contact-info mt-3">
                  <div className="col-lg-4 bg-2-color bg-multi-color">
                    <div className="contact-info-item d-flex align-items-center justify-content-center bg-secondary py-4 py-lg-0">
                      {/* <i className="fa fa-3x fa-envelope-open text-primary mr-4"></i> */}
                      <div className="">
                        <Link className="mb-2" to='/buynow?category_id=14'><h5 className="mb-2">AC Cleaning</h5></Link>
                        {/* <p className="m-0">helpdesk@safaixyz.com</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 bg-2-color bg-multi-color">
                    <div className="contact-info-item d-flex align-items-center justify-content-center text-white py-4 py-lg-0">
                      {/* <i className="fa fa-3x fa-phone-alt text-secondary mr-4"></i> */}
                      <div className="">
                        <h5 className="mb-2">More Services Coming Soon</h5>
                        {/* <p className="m-0">+91-8006305606</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <b>Washroom Cleaning:</b> We ensure your washrooms are sparkling
                clean and hygienic, leaving no corner untouched.
                <br />
                <br />
                <b>Deep House Cleaning: </b>Our thorough deep cleaning services
                encompass every nook and cranny of your home, ensuring a
                pristine living space for you and your family.
                <br />
                <br />
                <b>Kitchen Cleaning: </b>Say goodbye to grease and grime with
                our specialized kitchen cleaning services, leaving your cooking
                space immaculate and inviting.
                <br />
                <br />
                In addition to our core services, SafaiXYZ offers a range of
                attractive mini services, including:
                <br />
                <br />
                <b>Fan Cleaning: </b>Keep your ceiling fans free of dust and
                debris for a fresher, healthier indoor environment.
                <br />
                <b>Ghar Ke Jale Cleaning: </b>We pay attention to detail,
                ensuring even the smallest areas of your home are clean and
                tidy.
                <br />
                <br />
                Fridge Cleaning: Say hello to a sanitized refrigerator with our
                expert cleaning techniques, prolonging the life of your
                appliance and keeping your food fresh.
                <br />
                <br />
                <b>Sofa Cleaning: </b>Revitalize your upholstery with our
                professional sofa cleaning services, removing stains and odors
                for a cozy and inviting living space.
                <br /> */}
                <br />
                At SafaiXYZ, we understand the importance of affordability
                without compromising on quality. That's why we offer our
                services at competitive prices, ensuring exceptional value for
                our customers.
                <br />
                <br />
                Furthermore, we are excited to announce our expansion into
                corporate cleaning services, catering to the needs of businesses
                with flexible full-time and part-time options. Whether you're a
                homeowner or a corporate client, SafaiXYZ is committed to
                delivering unparalleled cleaning solutions tailored to your
                specific requirements.
                <br />
                <br />
                Experience the difference with SafaiXYZ - where cleanliness
                meets convenience, affordability, and excellence. Contact us
                today to schedule your next cleaning appointment!
              </p>
              {/* <Link to="/" className="btn btn-primary">
                More Services
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      {/* Services End  */}

      {/* Testimonial Start  */}
      {/* <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 pt-lg-5 pb-5">
              <h6 className="text-secondary font-weight-semi-bold text-uppercase mb-3">
                Testimonial
              </h6>
              <h1 className="section-title mb-5">What Our Clients Say</h1>
              <div className="owl-carousel testimonial-carousel position-relative">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <img className="img-fluid" src={testimonial1Image} alt="" />
                    <div className="ml-3">
                      <h5>Client Name</h5>
                      <i>Profession</i>
                    </div>
                  </div>
                  <p>
                    Tempor at diam tempor sed. Sanctus dolor ipsum ut nonumy
                    amet clita sea. Sed eos sed dolores vero no. Ipsum elitr
                    elitr stet dolor lorem erat. Diam no amet sea justo vero ut.
                    Dolor ipsum kasd ipsum dolore.
                  </p>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <img className="img-fluid" src={testimonial2Image} alt="" />
                    <div className="ml-3">
                      <h5>Client Name</h5>
                      <i>Profession</i>
                    </div>
                  </div>
                  <p>
                    Tempor at diam tempor sed. Sanctus dolor ipsum ut nonumy
                    amet clita sea. Sed eos sed dolores vero no. Ipsum elitr
                    elitr stet dolor lorem erat. Diam no amet sea justo vero ut.
                    Dolor ipsum kasd ipsum dolore.
                  </p>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <img className="img-fluid" src={testimonial3Image} alt="" />
                    <div className="ml-3">
                      <h5>Client Name</h5>
                      <i>Profession</i>
                    </div>
                  </div>
                  <p>
                    Tempor at diam tempor sed. Sanctus dolor ipsum ut nonumy
                    amet clita sea. Sed eos sed dolores vero no. Ipsum elitr
                    elitr stet dolor lorem erat. Diam no amet sea justo vero ut.
                    Dolor ipsum kasd ipsum dolore.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5" style={{ minHeight: "400px;" }}>
              <div className="position-relative h-100 rounded overflow-hidden">
                <img
                  className="position-absolute w-100 h-100"
                  src={testimonialImage}
                  style={{ objectFit: "cover;" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Testimonial End  */}
      <Footer />
    </>
  );
}

export default Service;

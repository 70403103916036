import React from 'react'
import { Link } from 'react-router-dom'

function CopyRight() {
  return (
    <>
      <div className="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5" style={{ borderColor: "#3e3e4e !important"}}>
        <div className="row">
          <div className="col-lg-6 text-center text-md-left mb-3 mb-md-0">
            <p className="m-0 text-white">
              &copy;<Link to='#'>SafaiXYZ</Link> All Rights Reserved.
            </p>
          </div>
          <div className="col-lg-6 text-center text-md-right">
            <ul className="nav d-inline-flex">
              <li className="nav-item">
                <Link className="nav-link text-white py-0" to="/privacy-policy">Privacy</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white py-0" to="#">Terms</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white py-0" to="#">FAQs</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white py-0" to="#">Help</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default CopyRight
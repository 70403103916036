import React from 'react'
import userImage  from '../img/user.jpg'
import carouselImage from '../img/carousel-1.jpg'
import blog1Image from '../img/blog-1.jpg'
import blog2Image from '../img/blog-2.jpg'
import blog3Image from '../img/blog-3.jpg'
import Footer from './Footer'

function Single() {
  return (
    <>
      {/* Header Start  */}
      <div className="container-fluid">
          <div className="row">
              <div className="col-lg-3 bg-secondary-1 d-none d-lg-block">
                  <a href="" className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
                      <h1 className="m-0 display-3 text-primary">SafaiXYZ</h1>
                  </a>
              </div>
              <div className="col-lg-9">
                  <div className="row bg-dark d-none d-lg-flex">
                      <div className="col-lg-7 text-left text-white">
                          <div className="h-100 d-inline-flex align-items-center border-right border-primary py-2 px-3">
                              <i className="fa fa-envelope text-primary mr-2"></i>
                              <small>helpdesk@safaixyz.com</small>
                          </div>
                          <div className="h-100 d-inline-flex align-items-center py-2 px-2">
                              <i className="fa fa-phone-alt text-primary mr-2"></i>
                              <small>+91-8006305606</small>
                          </div>
                      </div>
                      <div className="col-lg-5 text-right">
                          <div className="d-inline-flex align-items-center pr-2">
                              <a className="text-primary p-2" href="">
                                  <i className="fab fa-facebook-f"></i>
                              </a>
                              <a className="text-primary p-2" href="">
                                  <i className="fab fa-twitter"></i>
                              </a>
                              <a className="text-primary p-2" href="">
                                  <i className="fab fa-linkedin-in"></i>
                              </a>
                              <a className="text-primary p-2" href="">
                                  <i className="fab fa-instagram"></i>
                              </a>
                              <a className="text-primary p-2" href="">
                                  <i className="fab fa-youtube"></i>
                              </a>
                          </div>
                      </div>
                  </div>
                  <nav className="navbar navbar-expand-lg bg-white navbar-light p-0">
                      <a href="" className="navbar-brand d-block d-lg-none">
                          <h1 className="m-0 display-4 text-primary">SafaiXYZ</h1>
                      </a>
                      <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                          <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                          <div className="navbar-nav mr-auto py-0">
                              <a href="/" className="nav-item nav-link">Home</a>
                              <a href="about" className="nav-item nav-link">About</a>
                              <a href="service" className="nav-item nav-link">Service</a>
                              <a href="project" className="nav-item nav-link">Project</a>
                              <div className="nav-item dropdown">
                                  <a href="#" className="nav-link dropdown-toggle active" data-toggle="dropdown">Pages</a>
                                  <div className="dropdown-menu rounded-0 m-0">
                                      <a href="/blog" className="dropdown-item">Latest Blog</a>
                                      <a href="/single" className="dropdown-item active">Blog Detail</a>
                                  </div>
                              </div>
                              <a href="contact" className="nav-item nav-link">Contact</a>
                          </div>
                          <a href="" className="btn btn-primary mr-3 d-none d-lg-block">Get A Quote</a>
                      </div>
                  </nav>
              </div>
          </div>
      </div>
      {/* Header End  */}

      {/* Page Header Start  */}
      <div className="container-fluid bg-primary py-5 mb-5">
          <div className="container py-5">
              <div className="row align-items-center py-4">
                  <div className="col-md-6 text-center text-md-left">
                      <h1 className="display-4 mb-4 mb-md-0 text-secondary-1 text-uppercase">Detail Page</h1>
                  </div>
                  <div className="col-md-6 text-center text-md-right">
                      <div className="d-inline-flex align-items-center">
                          <a className="btn btn-sm btn-outline-light" href="">Home</a>
                          <i className="fas fa-angle-double-right text-light mx-2"></i>
                          <a className="btn btn-sm btn-outline-light disabled" href="">Detail Page</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      {/* Page Header End  */}

      {/* Detail Start  */}
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="mb-5">
                <div className="d-flex mb-2">
                  <a className="text-secondary-1 text-uppercase font-weight-medium" href="#">Admin</a>
                  <span className="text-primary px-2">|</span>
                  <a className="text-secondary-1 text-uppercase font-weight-medium" href="#">Cleaning</a>
                  <span className="text-primary px-2">|</span>
                  <a className="text-secondary-1 text-uppercase font-weight-medium" href="#">January 01, 2045</a>
                </div>
                <h1 className="section-title mb-3">Amet sit kasd ipsum lorem clita ipsum duo clita</h1>
              </div>

              <div className="mb-5">
                <img className="img-fluid rounded w-100 mb-4" src={carouselImage} alt="Image"/>
                <p>Sadipscing labore amet rebum est et justo gubergren. Et eirmod ipsum sit diam ut magna lorem.
                  Nonumy vero labore lorem sanctus rebum et lorem magna kasd, stet amet magna accusam
                  consetetur eirmod. Kasd accusam sit ipsum sadipscing et at at sanctus et. Ipsum sit gubergren dolores
                  et, consetetur justo invidunt at et aliquyam ut et vero clita. Diam sea sea no sed dolores diam
                  nonumy, gubergren sit stet no diam kasd vero.</p>
                <p>Voluptua est takimata stet invidunt sed rebum nonumy stet, clita aliquyam dolores vero stet
                  consetetur elitr takimata rebum sanctus. Sit sed accusam stet sit nonumy kasd diam dolores,
                  sanctus lorem kasd duo dolor dolor vero sit et. Labore ipsum duo sanctus amet eos et.
                  Consetetur no sed et aliquyam ipsum justo et, clita lorem sit vero amet amet est dolor elitr, stet et
                  no diam sit. Dolor erat justo dolore sit invidunt.</p>
                <h2 className="mb-4">Est dolor lorem et ea</h2>
                <img className="img-fluid rounded w-50 float-left mr-4 mb-3" src={blog1Image} alt="Image"/>
                <p>Diam dolor est labore duo invidunt ipsum clita et, sed et lorem voluptua tempor invidunt at
                  est sanctus sanctus. Clita dolores sit kasd diam takimata justo diam lorem sed. Magna amet sed
                  rebum eos. Clita no magna no dolor erat diam tempor rebum consetetur, sanctus labore sed nonumy
                  diam lorem amet eirmod. No at tempor sea diam kasd, takimata ea nonumy elitr sadipscing gubergren
                  erat. Gubergren at lorem invidunt sadipscing rebum sit amet ut ut, voluptua diam dolores at
                  sadipscing stet. Clita dolor amet dolor ipsum vero ea ea eos. Invidunt sed diam dolores
                  takimata dolor dolore dolore sit. Sit ipsum erat amet lorem et, magna sea at sed et eos. Accusam
                  eirmod kasd lorem clita sanctus ut consetetur et. Et duo tempor sea kasd clita ipsum et. Takimata
                  kasd diam justo est eos erat aliquyam et ut. Ea sed sadipscing no justo et eos labore, gubergren
                  ipsum magna dolor lorem dolore, elitr aliquyam takimata sea kasd dolores diam, amet et est
                  accusam labore eirmod vero et voluptua. Amet labore clita duo et no. Rebum voluptua magna
                  eos magna, justo gubergren labore sit voluptua eos.</p>
                <h3 className="mb-4">Est dolor lorem et ea</h3>
                <img className="img-fluid rounded w-50 float-right ml-4 mb-3" src={blog2Image} alt="Image"/>
                <p>Diam dolor est labore duo invidunt ipsum clita et, sed et lorem voluptua tempor invidunt at
                  est sanctus sanctus. Clita dolores sit kasd diam takimata justo diam lorem sed. Magna amet sed
                  rebum eos. Clita no magna no dolor erat diam tempor rebum consetetur, sanctus labore sed nonumy
                  diam lorem amet eirmod. No at tempor sea diam kasd, takimata ea nonumy elitr sadipscing gubergren
                  erat. Gubergren at lorem invidunt sadipscing rebum sit amet ut ut, voluptua diam dolores at
                  sadipscing stet. Clita dolor amet dolor ipsum vero ea ea eos. Invidunt sed diam dolores
                  takimata dolor dolore dolore sit. Sit ipsum erat amet lorem et, magna sea at sed et eos. Accusam
                  eirmod kasd lorem clita sanctus ut consetetur et. Et duo tempor sea kasd clita ipsum et. Takimata
                  kasd diam justo est eos erat aliquyam et ut. Ea sed sadipscing no justo et eos labore, gubergren
                  ipsum magna dolor lorem dolore, elitr aliquyam takimata sea kasd dolores diam, amet et est
                  accusam labore eirmod vero et voluptua. Amet labore clita duo et no.</p>
              </div>

              <div className="mb-5">
                <h3 className="mb-4 section-title">3 Comments</h3>
                <div className="media mb-4">
                  <img src={userImage} alt="Image" className="img-fluid rounded-circle mr-3 mt-1" style={{width: '45px'}}/>
                  <div className="media-body">
                    <h6>John Doe <small><i>01 Jan 2045 at 12:00pm</i></small></h6>
                    <p>Diam amet duo labore stet elitr ea clita ipsum, tempor labore accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod ipsum clita, at tempor amet ipsum diam tempor sit.</p>
                    <button className="btn btn-sm btn-light">Reply</button>
                  </div>
                </div>
                <div className="media mb-4">
                  <img src={userImage} alt="Image" className="img-fluid rounded-circle mr-3 mt-1" style={{width: '45px'}}/>
                  <div className="media-body">
                    <h6>John Doe <small><i>01 Jan 2045 at 12:00pm</i></small></h6>
                    <p>Diam amet duo labore stet elitr ea clita ipsum, tempor labore accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod ipsum clita, at tempor amet ipsum diam tempor sit.</p>
                    <button className="btn btn-sm btn-light">Reply</button>
                    <div className="media mt-4">
                      <img src={userImage} alt="Image" className="img-fluid rounded-circle mr-3 mt-1" style={{width: '45px'}}/>
                      <div className="media-body">
                        <h6>John Doe <small><i>01 Jan 2045 at 12:00pm</i></small></h6>
                        <p>Diam amet duo labore stet elitr ea clita ipsum, tempor labore accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed sed eirmod ipsum clita, at tempor amet ipsum diam tempor sit.</p>
                        <button className="btn btn-sm btn-light">Reply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-light rounded p-5">
                <h3 className="mb-4 section-title">Leave a comment</h3>
                <form>
                  <div className="form-row">
                    <div className="form-group col-sm-6">
                      <label htmlFor="name">Name *</label>
                      <input type="text" className="form-control" id="name" />
                    </div>
                    <div className="form-group col-sm-6">
                      <label htmlFor="email">Email *</label>
                      <input type="email" className="form-control" id="email" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="website">Website</label>
                    <input type="url" className="form-control" id="website" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message *</label>
                    <textarea id="message" cols="30" rows="5" className="form-control"></textarea>
                  </div>
                  <div className="form-group mb-0">
                    <input type="submit" value="Leave Comment" className="btn btn-primary" />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <div className="d-flex flex-column text-center bg-secondary rounded mb-5 py-5 px-4">
                <img src={userImage} className="img-fluid rounded-circle mx-auto mb-3" style={{ width: "100px" }} alt="User" />
                <h3 className="text-white mb-3">John Doe</h3>
                <p className="text-white m-0">
                  Conset elitr erat vero dolor ipsum et diam, eos dolor lorem ipsum,
                  ipsum ipsum sit no ut est. Guber ea ipsum erat kasd amet est elitr ea sit.
                </p>
              </div>
              <div className="mb-5">
                <div className="w-100">
                  <div className="input-group">
                    <input type="text" className="form-control" style={{ padding: "25px" }} placeholder="Keyword" />
                    <div className="input-group-append">
                      <button className="btn btn-primary px-4">Search</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <h3 className="mb-4 section-title">Categories</h3>
                <ul className="list-inline m-0">
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-secondary mr-2"></i>Web Design</a>
                    <span className="badge badge-primary badge-pill">150</span>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-secondary mr-2"></i>Web Development</a>
                    <span className="badge badge-primary badge-pill">131</span>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-secondary mr-2"></i>Online Marketing</a>
                    <span className="badge badge-primary badge-pill">78</span>
                  </li>
                  <li className="mb-1 py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-secondary mr-2"></i>Keyword Research</a>
                    <span className="badge badge-primary badge-pill">56</span>
                  </li>
                  <li className="py-2 px-3 bg-light d-flex justify-content-between align-items-center">
                    <a className="text-dark" href="#"><i className="fa fa-angle-right text-secondary mr-2"></i>Email Marketing</a>
                    <span className="badge badge-primary badge-pill">98</span>
                  </li>
                </ul>
              </div>
              <div className="mb-5">
                <img src={blog1Image} alt="Blog Visual" className="img-fluid rounded" />
              </div>
              <div className="mb-5">
                <h3 className="mb-4 section-title">Recent Post</h3>
                {["1", "2", "3", "1", "2"].map((item, index) => (
                  <div key={index} className="d-flex align-items-center border-bottom mb-3 pb-3">
                    <img className="img-fluid rounded" src={blog1Image} style={{ width: "80px", height: "80px", objectFit: "cover" }} alt="" />
                    <div className="d-flex flex-column pl-3">
                      <a className="text-dark mb-2" href="#">Elitr diam amet sit elitr magna ipsum ipsum dolor</a>
                      <div className="d-flex">
                        <small><a className="text-secondary-1 text-uppercase font-weight-medium" href="#">Admin</a></small>
                        <small className="text-primary px-2">|</small>
                        <small><a className="text-secondary-1 text-uppercase font-weight-medium" href="#">Cleaning</a></small>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mb-5">
                <img src={blog2Image} alt="Additional Blog Visual" className="img-fluid rounded" />
              </div>
              <div className="mb-5">
                <h3 className="mb-4 section-title">Tag Cloud</h3>
                <div className="d-flex flex-wrap m-n1">
                  {["Design", "Development", "Marketing", "SEO", "Writing", "Consulting"].map(tag => (
                    <a key={tag} href="#" className="btn btn-outline-secondary m-1">{tag}</a>
                  ))}
                </div>
              </div>
              <div className="mb-5">
                <img src={blog3Image} alt="Additional Blog Visual" className="img-fluid rounded" />
              </div>
              <div>
                <h3 className="mb-4 section-title">Plain Text</h3>
                <p>
                  Aliquyam sed lorem stet diam dolor sed ut sit. Ut sanctus erat ea est aliquyam dolor et. Et no
                  consetetur eos labore ea erat voluptua et. Et aliquyam dolore sed erat. Magna sanctus sed eos
                  tempor rebum dolor, tempor takimata clita sit et elitr ut eirmod.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Detail End  */}
      <Footer/>
    </>
  )
}

export default Single
import React from "react";
import ClientImage1 from '../img/Clients/kamal.jpg'
import ClientImage2 from '../img/Clients/aayush.jpeg'
import ClientImage3 from '../img/Clients/anmol.jpeg'
import ClientImage4 from '../img/Clients/neetu.jpeg'
import ClientImage5 from '../img/Clients/rohit.jpeg'
import ClientImage6 from '../img/Clients/simran.jpeg'

const preventContextMenu = (e) => {
  e.preventDefault();
};

function Testimonial() {
  return (
    <>
      <div className="container-fluid">
        <div className="bg-testimonial">
          <div className="container ">
            <h1 className="mb-3 text-center text-black">
              What Our Clients Say
            </h1>
            <div className="row">
              <div className="col-lg-6 pt-lg-5">
                <div className="owl-carousel testimonial-carousel position-relative">
                  <div className="d-flex flex-column text-black">
                    <div className="d-flex align-items-center mb-3">
                      <img className="img-fluid" src={ClientImage1} alt="" style={{width: "50px", height: "50px"}} onContextMenu={preventContextMenu} />
                      <div className="ml-3">
                        <h5 className="text-primary">Kamal Joshi</h5>
                        <i>Gaur City</i>
                      </div>
                    </div>
                    <p>
                      I am thoroughly impressed with the professional cleaning services provided. My home has never looked this spotless, and the team was punctual and courteous. Highly recommend their services to anyone in need of a reliable cleaning company!
                    </p>
                  </div>
                  <div className="d-flex flex-column text-black">
                    <div className="d-flex align-items-center mb-3">
                      <img className="img-fluid" src={ClientImage2} alt="" style={{width: "50px", height: "50px"}} onContextMenu={preventContextMenu} />
                      <div className="ml-3">
                        <h5 className="text-primary">Aayush Pandey</h5>
                        <i>Mahagun Mywoods</i>
                      </div>
                    </div>
                    <p>
                      The cleaning team did an outstanding job, leaving every corner of my house sparkling clean. Their attention to detail and efficiency were exceptional. I will definitely be using their services again!
                    </p>
                  </div>
                  <div className="d-flex flex-column text-black">
                    <div className="d-flex align-items-center mb-3">
                      <img className="img-fluid" src={ClientImage3} alt="" style={{width: "50px", height: "50px"}} onContextMenu={preventContextMenu} />
                      <div className="ml-3">
                        <h5 className="text-primary">Anmol Tyagi</h5>
                        <i>SuperTech Eco Village-1</i>
                      </div>
                    </div>
                    <p>
                      Exceptional service and top-notch professionalism! The cleaners were friendly, thorough, and left my apartment looking immaculate. I couldn't be happier with the results.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pt-lg-5">
                <div className="owl-carousel testimonial-carousel position-relative">
                  <div className="d-flex flex-column text-black">
                    <div className="d-flex align-items-center mb-3">
                      <img className="img-fluid" src={ClientImage4} alt="" style={{width: "50px", height: "50px"}} onContextMenu={preventContextMenu} />
                      <div className="ml-3">
                        <h5 className="text-primary">Neetu Tiwari</h5>
                        <i>Flora Heritage 16B, Sector 1, Greater Noida</i>
                      </div>
                    </div>
                    <p>
                      From booking to completion, the entire process was seamless. The cleaners were professional and did an amazing job. My house looks and smells wonderful. I will definitely be a repeat customer!
                    </p>
                  </div>
                  <div className="d-flex flex-column text-black">
                    <div className="d-flex align-items-center mb-3">
                      <img className="img-fluid" src={ClientImage5} alt="" style={{width: "50px", height: "50px"}} onContextMenu={preventContextMenu} />
                      <div className="ml-3">
                        <h5 className="text-primary">Rohit Kumar</h5>
                        <i>Spring Meadows</i>
                      </div>
                    </div>
                    <p>
                      The cleaning service exceeded my expectations! They were prompt, efficient, and left my office in pristine condition. I highly recommend them to anyone looking for quality cleaning services.
                    </p>
                  </div>
                  <div className="d-flex flex-column text-black">
                    <div className="d-flex align-items-center mb-3">
                      <img className="img-fluid" src={ClientImage6} alt="" style={{width: "50px", height: "50px"}} onContextMenu={preventContextMenu} />
                      <div className="ml-3">
                        <h5 className="text-primary">Simran Chhabra</h5>
                        <i>SKA Metro Ville Eta 2 , Greater Noida</i>
                      </div>
                    </div>
                    <p>
                      I am extremely pleased with the cleaning crew's work. They were detail-oriented and ensured every part of my home was thoroughly cleaned. Fantastic job and excellent customer service!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;

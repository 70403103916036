import React, { useState, useEffect } from "react";

function MiniSerices({ addToCart, isProductInCart, selectedProduct, handleDecrease, handleIncrease }) {

  const [cartItems, setCartItems] = useState(() => JSON.parse(localStorage.getItem("cart")) || []);
  useEffect(() => {
    const handleStorageChange = () => {
      setCartItems(JSON.parse(localStorage.getItem("cart")) || []);
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const getProductQuantity = (productId) => {
    const product = cartItems.find(item => item.id === productId);
    return product ? product.quantity : 0;
  };

  return (
    <>
      {/* <div className="container-fluid"> */}
      <div className="container">
        <div className="row popular-service-bg">
          <div className="col-lg-12">
            <h1 className="section-title-1 mb-3">Special Mini Services</h1>
          </div>
          {selectedProduct &&
            // data.map((miniservice) =>
            // miniservice.products.map((product) => (
            selectedProduct.mini_services.map((service) => (
              <div className="col-lg-3 col-md-3 mb-3">
                <div className="border-mini-service service-mini-inline rounded">
                  <img
                    className="img-fluid-mini rounded w-25"
                    src={service?.url}
                    alt=""
                  />
                  <div className="service-mini-box main-app-color">
                    <span className="mb-n1">{service?.title}</span>
                    <div className="row service-price-1">
                      <div className="col-md-6">
                        <div className="text-uppercase">₹ {service?.price}</div>
                      </div>
                      <div className="col-md-6">
                        {isProductInCart(service.id) ? (
                          <div className="service-quantity">
                            <button
                              className="btn-sm btn-info btn-border"
                              onClick={() => {
                                handleDecrease(service);
                                setCartItems(JSON.parse(localStorage.getItem("cart")) || []);
                              }}
                            >
                              -
                            </button>
                            <span> {getProductQuantity(service.id)} </span>
                            <button
                              className="btn-sm btn-info btn-border"
                              onClick={() => {
                                handleIncrease(service);
                                setCartItems(JSON.parse(localStorage.getItem("cart")) || []);
                              }}
                            >
                              +
                            </button>
                          </div>
                        ) : (
                          <button
                            className="btn-sm btn-info btn-border"
                            onClick={() => {
                              addToCart(service);
                              setCartItems(JSON.parse(localStorage.getItem("cart")) || []);
                            }}
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
            // )))
          }
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default MiniSerices;

import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import { APIEndtPoint } from "./Apis";
import { Link, useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    phone_number: ""
  });
  const [errors, setErrors] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const requestBody = {
      contact: {
        ...formData
      },
    };

    try {
      const response = await fetch(APIEndtPoint + "/contacts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setShowPopup(true);
        console.log("Contact Data Submitted Successful");
        setErrors([]);
      } else {
        const errorResponse = await response.json();
        setErrors(errorResponse.errors);
        throw new Error("Failed to submit data");
      }
    } catch (error) {
      console.error("Error:", error);
    }finally {
      setIsSubmitting(false);
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    navigate('/');
  };

  return (
    <>
      <div className={showPopup ? 'blur-background' : ''}>

        {/* Header Start */}
        <Header data={'contact'} />
        {/* Header End */}

        {/* Page Header Start */}
        {/* <div className="container-fluid bg-primary py-5 mb-5">
        <div className="container py-5">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="display-4 mb-4 mb-md-0 text-secondary-1 text-uppercase">Contact</h1>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="d-inline-flex align-items-center">
                <a className="btn btn-sm btn-outline-light" href="/">Home</a>
                <i className="fas fa-angle-double-right text-light mx-2"></i>
                <a className="btn btn-sm btn-outline-light disabled" href="">Contact</a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        {/* Page Header End */}
        {/* <div className="container-fluid mb-5">
        <div className="bg-primary">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="display-4 text-center mb-4 text-secondary-1 text-uppercase text-white">
              Contact
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="d-inline-flex align-items-center">
                <a className="btn btn-sm btn-outline-light" href="/">
                  Home
                </a>
                <i className="fas fa-angle-double-right text-light mx-2"></i>
                <a
                  className="btn btn-sm btn-outline-light disabled"
                  href="/contact"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        {/* Contact Info Start  */}
        <div className="container-fluid mt-5">
          <div className="container">
            <h1 className="section-title mb-3 text-dark text-center">Get in Touch With Us</h1>
            <div className="row contact-info">
              <div className="col-lg-4 bg-multi-color">
                <Link to="https://www.google.com/maps/place/28%C2%B037'36.0%22N+77%C2%B022'40.6%22E/@28.6266772,77.3753575,17z/data=!3m1!4b1!4m4!3m3!8m2!3d28.6266725!4d77.3779324?entry=ttu" target="_blank">
                  <div className="contact-info-item d-flex align-items-center justify-content-center bg-primary text-white py-4 py-lg-0">
                    <i className="fa fa-3x fa-map-marker-alt text-secondary mr-4"></i>
                    <div className="">
                      <h5 className="mb-2">Our Office</h5>
                      <p className="m-0">Noida Sector 63, 201301</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 bg-multi-color">
                <Link to="mailto:helpdesk@safaixyz.com">
                  <div className="contact-info-item d-flex align-items-center justify-content-center bg-secondary py-4 py-lg-0">
                    <i className="fa fa-3x fa-envelope-open text-primary mr-4"></i>
                    <div className="">
                      <h5 className="mb-2">Email Us</h5>
                      <p className="m-0">helpdesk@safaixyz.com</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 bg-multi-color">
                <Link to="tel:+91-8006305606">
                  <div className="contact-info-item d-flex align-items-center justify-content-center bg-primary text-white py-4 py-lg-0">
                    <i className="fa fa-3x fa-phone-alt text-secondary mr-4"></i>
                    <div className="">
                      <h5 className="mb-2">Call Us</h5>
                      <p className="m-0">+91-8006305606</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Info End  */}

        {/* Contact Start */}
        <div className="container-fluid">
          <div className="container">
            <div className="row align-items-end mb-4">
              <div className="col-lg-12">
                {/* <h6 className="text-secondary font-weight-semi-bold text-uppercase mb-3">Contact Us</h6> */}
                <h1 className="section-title text-dark text-center mt-5">Contact Us For Cleaning Services</h1>
              </div>
              <div className="col-lg-6">
                {/* <h4 className="font-weight-normal text-muted mb-3">Eirmod kasd duo eos et magna, diam dolore stet sea clita sit ea erat lorem. Ipsum eos ipsum magna lorem stet</h4> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mb-5 mb-lg-0">
                <div className="contact-form">
                  <div id="success"></div>
                  <form name="sentMessage" id="contactForm" onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="col-sm-6 control-group">
                        <input type="text" className="form-control p-4" id="name" placeholder="Your Name" required data-validation-required-message="Please enter your name" onChange={handleInputChange} />
                        <p className="help-block text-danger"></p>
                      </div>
                      <div className="col-sm-6 control-group">
                        <input type="email" className="form-control p-4" id="email" placeholder="Your Email" required data-validation-required-message="Please enter your email" onChange={handleInputChange} />
                        <p className="help-block text-danger"></p>
                      </div>
                      <div className="col-sm-6 control-group">
                        <input type="text" className="form-control p-4" id="subject" placeholder="Subject" required data-validation-required-message="Please enter a subject" onChange={handleInputChange} />
                        <p className="help-block text-danger"></p>
                      </div>
                      <div className="col-sm-6 control-group">
                        <input type="text" className="form-control p-4" id="phone_number" placeholder="Phone Number" required data-validation-required-message="Please enter a phone number" onChange={handleInputChange} />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="control-group">
                      <textarea className="form-control p-4" rows="6" id="message" placeholder="Message" required data-validation-required-message="Please enter your message" onChange={handleInputChange}></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                    <div>
                      {errors.length > 0 && (
                        <div className="mt-3">
                          <p className="help-block text-danger text-center">{errors.map((error, index) => (
                            <React.Fragment key={index}>
                              {error}
                              {index !== errors.length - 1 && ", "}
                            </React.Fragment>
                          ))}</p>
                        </div>
                      )}
                      <button className="btn contact-btn btn-block py-3 px-5" type="submit" id="sendMessageButton" disabled={isSubmitting}>
                        {isSubmitting ? 'Sending...' : 'Send Message'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="col-lg-5" style={{ minHeight: '400px' }}>
              <div className="position-relative h-100 rounded overflow-hidden">
                <iframe style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143"
                  frameBorder="0" allowFullScreen aria-hidden="false" tabIndex="0"></iframe>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        {/* Contact End */}
        <Footer />
        <Popup open={showPopup} onClose={() => setShowPopup(false)} modal>
          {close => (
            <div className="bg-light text-center p-3">
              <button className="close" onClick={close}>&times;</button>
              <h3 className="mb-4 text-center">Your Contact Data has been submitted successfully!</h3>
              <p className="mb-4 text-center">We will get back to you as soon as possible.</p>
              <button className="btn btn-primary" onClick={handlePopupClose}>Okay</button>
            </div>
          )}
        </Popup>
      </div>
    </>
  );
}

export default Contact;

import React from "react";
import Footer from "./Footer";
import verifiedImage from "../img/verified.png";
import { useLocation } from "react-router-dom";
import Header from "./Header";

function Booked() {
  const location = useLocation();
  const bookingDetails = location.state?.bookingDetails?.booking
    ? location.state?.bookingDetails?.booking
    : [];

  return (
    <>
      <style jsx>{`
        main-container {
          font-family: sans-serif;
          margin: 0;
          padding: 0;
        }

        .booking-container {
          border: 1px solid #ddd;
          padding: 20px;
          border-radius: 5px;
          background: #fff;
        }

        .booking-id {
          font-size: 20px;
          margin-bottom: 10px;
          text-align: center;
        }

        .message {
          font-size: 18px;
          font-weight: bold;
          margin: 30px;
          text-align: center;
        }

        .info {
          margin-bottom: 20px;
        }

        .info-row {
          margin-bottom: 5px;
          display: flex;
          justify-content: space-between;
        }

        .info-label {
          font-weight: bold;
        }

        .calendar-options {
          text-align: center;
        }

        .calendar-options a {
          margin-right: 10px;
          text-decoration: none;
          color: #000;
        }

        .calendar-options a:hover {
          color: #0066cc;
        }
      `}</style>
      {/* Header start */}
      <Header />
      {/* Header end */}
      <div className="container-fluid">
        <div className="container">
          {bookingDetails.order_id ? (
            <>
              <div className="booking-container">
                <img
                  src={verifiedImage}
                  alt=""
                  style={{ height: "100px", width: "100px", marginLeft: "40%" }}
                />
                <div className="booking-id">
                  Booking ID: #{bookingDetails.order_id}
                </div>
                <div className="message">
                  Your Appointment Booked Successfully!
                </div>
                <div className="info">
                  {bookingDetails?.product && (
                    <div className="info-row">
                      <div className="left">
                        <span className="info-label">Service:</span>
                      </div>
                      <div className="right">
                        {bookingDetails.product.map((product) => (
                          <li key={product.id} className="info-value">
                            {product.title}
                          </li>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="info-row">
                    <span className="info-label">Date & Time:</span>
                    <span className="info-value">
                      {bookingDetails.booking_date}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-label">Time Slot:</span>
                    <span className="info-value">
                      {bookingDetails.booking_time_slot}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-label">Customer Name:</span>
                    <span className="info-value">
                      {bookingDetails.first_name} {bookingDetails.last_name}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="container-fluid mb-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">You don't have any booking</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Booked;

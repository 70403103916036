import React from 'react'

function SubCtegoryDetails() {
  return (
    <>
      <div className="container-fluid">
        {/* {data && data.map(category => ( */}
          <div className="container">
            {/* {category.products.map(product => ( */}
              <div className="row">
                <div className="col-lg-12">
                  {/* <h1 className="section-title-1">{product ? product.title : 'Loading...'}</h1> */}
                  <h1 className="section-title-1">Sub Category 1</h1>
                  <hr />
                </div>
                <div className="col-lg-4">
                  {/* <img className="img-fluid rounded" src={product ? product.images.url : ''} alt="Service Image"/> */}
                  <img className="img-fluid rounded" src="" alt="Service Image"/>
                </div>
                <div className="col-lg-6">
                  <p>
                  {/* {product ? product.description : 'Loading...'} */}
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia, et. Totam recusandae consectetur, expedita fuga unde libero repellat reprehenderit nemo! Perferendis, tempore voluptate velit aut quos sed quidem rerum culpa?
                  </p>
                  <p>
                    {/* {product ? product.content : 'Loading...'} */}
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi delectus obcaecati vero reiciendis sit. Commodi illo placeat esse officia suscipit atque architecto, blanditiis, aperiam perferendis corrupti, unde nesciunt itaque iure.
                  </p>
                </div>
                <div className="col-lg-2">
                  <div className="main-price">
                    <div className="price-with-offer">
                      {/* <s>₹ 220</s> */}
                      {/* <b>₹ {product ? product.price : 'Loading...'}</b> */}
                      <b>₹ 200</b>
                    </div>
                    {/* <button className="" onClick={() => addToCart(product)}>
                      {isProductInCart(product ? product.id : null) ? 'Remove' : 'Add'}
                    </button> */}
                    <button>Add</button>
                  </div>
                </div>
                  <p>
                    {/* {product ? product.description : 'Loading...'} */}
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci eveniet deserunt perferendis consequuntur molestiae nulla magnam molestias soluta, aperiam porro hic exercitationem quos ipsam et natus vero accusantium est quis!
                  </p>
                  <p>
                    {/* {product ? product.description : 'Loading...'} */}
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam, tenetur quis aliquid sequi modi eaque vel commodi possimus mollitia nobis dolor quod reprehenderit praesentium alias voluptate, sapiente nulla tempora voluptates!
                  </p>
                  <p>
                    {/* {product ? product.description : 'Loading...'} */}
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta id, fuga commodi exercitationem harum iusto quia quas blanditiis nostrum quis odio sequi in quasi perferendis tempore praesentium ipsum. Nisi, corrupti?
                  </p>
              </div>
            {/* ))} */}
          </div>
        {/* ))} */}
      </div>
    </>
  )
}

export default SubCtegoryDetails
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { APIEndtPoint } from "./Apis";

function BasicDetails() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    booking_date: "",
    booking_time_slot: "",
    pin_code: "",
    full_address: "",
    comment: "",
  });
  const [errors, setErrors] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    document.getElementById("booking_date").setAttribute("min", today);
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const cartItem = localStorage.getItem("cart");
    const cartData = JSON.parse(cartItem);
    let products = [];

    if (Array.isArray(cartData)) {
      products = cartData.map((product) => ({
        product_id: product.id,
        product_count: product.quantity,
      }));
    } else {
      console.log("No valid cart data found or cart data is not an array.");
      return;
    }

    const requestBody = {
      booking: {
        ...formData,
        products: products,
      },
    };

    try {
      const response = await fetch(APIEndtPoint + "/bookings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        console.log("Booking successful");
        const result = await response.json();
        console.log("Response:", result);
        localStorage.clear();
        navigate("/booked", { state: { bookingDetails: result } });
      } else {
        const errorResponse = await response.json();
        setErrors(errorResponse);
        throw new Error("Failed to create booking");
      }
    } catch (error) {
      console.error("Error:", error);
    }finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {/* Basic Details Start  */}
      <div className="cart-totals">
        <div className="">
          <div className="">
            <div className="">
              <h4 className="section">Add Basic Details:</h4>
            </div>
          </div>
          <div className="">
            <hr />
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="col-sm-6">
                  <label htmlFor="first_name">First Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    placeholder="First Name"
                    required
                    onChange={handleInputChange}
                    data-validation-required-message="Please enter your first name."
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="last_name">Last Name *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    placeholder="Last Name"
                    required
                    onChange={handleInputChange}
                    data-validation-required-message="Please enter your last name."
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="email">Email *</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                    required
                    onChange={handleInputChange}
                    data-validation-required-message="Please enter your email."
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="phone_number">Phone Number *</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone_number"
                    placeholder="Phone Number"
                    required
                    onChange={handleInputChange}
                    data-validation-required-message="Please enter your phone number."
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="booking_date">Date *</label>
                  <input
                    type="date"
                    className="form-control"
                    id="booking_date"
                    required
                    onChange={handleInputChange}
                    data-validation-required-message="Please select a date."
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="booking_time_slot">Time Slot *</label>
                  <select
                    className="form-control"
                    id="booking_time_slot"
                    required
                    onChange={handleInputChange}
                    data-validation-required-message="Please select a time slot."
                  >
                    <option value="">Select a Time Slot</option>
                    <option value="Morning">Morning</option>
                    <option value="Evening">Evening</option>
                  </select>
                  <p className="help-block text-danger"></p>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="pin_code">Pin Code *</label>
                  <input
                    type="number"
                    className="form-control"
                    id="pin_code"
                    placeholder="Pin Code"
                    required
                    onChange={handleInputChange}
                    data-validation-required-message="Please enter your Pin code."
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="full_address">Full Address *</label>
                  <input
                    type="text"
                    className="form-control"
                    id="full_address"
                    placeholder="Full Address"
                    required
                    onChange={handleInputChange}
                    data-validation-required-message="Please enter your Full Address."
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="col-sm-12">
                  <label htmlFor="comment">Additional Comment</label>
                  <input
                    type="text"
                    className="form-control"
                    id="comment"
                    placeholder="Add Additional Comment"
                    onChange={handleInputChange}
                  />
                  {errors && (
                    <p className="help-block text-danger">{errors.message}</p>
                  )}
                </div>
              </div>
              <button
                className="btn btn-primary btn-block mt-3 py-3 px-5"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Book Service'}
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* Basic Details End  */}
    </>
  );
}

export default BasicDetails;

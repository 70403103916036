import React, { useState, useEffect } from "react";
// import blog1Image from "../img/blog-1.jpg";
import "../css/buynow.css";

import MiniSerices from "./MiniSerices";
import PopularCategory from "./PopularCategory";
import { useLocation } from "react-router-dom";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Tab = (data) => {
  const [ToggleState, setToggleState] = useState(0);
  const data1 = data.data[0];
  const query = useQuery();
  const categoryId = query.get("category_id");

  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(() => {
    setToggleState(0);
  }, [data1]);

  const toggleContent = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";
  console.log(data.data[0], "******RightTab ");
  const [expanded, setExpanded] = useState({});

  function truncateText(text, numLines) {
    const lines = text.split("\n");
    if (lines.length > numLines) {
      return lines.slice(0, numLines);
    }
    return text;
  }

  return (
    <>
      <div className="container-tab col-lg-12 px-0 py-0">
        <ul className="tab-list popular-service-bg">
          {data &&
            data.data[0] &&
            data.data[0].products.map((product, index) => (
              <>
                <li
                  key={product.id}
                  className={`tabs ${getActiveClass(index, "active-tabs")}`}
                  onClick={() => toggleTab(index)}
                >
                  {product.short_title}
                </li>
              </>
            ))}
        </ul>
        <div className="content-container">
          {data &&
            data.data[0] &&
            data.data[0].products.map((product, index) => (
              <>
                <div
                  className={`tab-content-title-box ${getActiveClass(
                    index,
                    "active-content"
                  )}`}
                >
                  <div key={product.id} class="">
                    <div className="row">
                      <div className="col-lg-3">
                        <img class="img-fluid rounded" src={product.images?.url} alt="" />
                      </div>
                      <div className="col-lg-9">
                        <h3>
                          <span class="">{product.title}</span>
                        </h3>
                        <small class="text-uppercase price-value-box">
                          <h3 className="text-dark">₹ {product.price}</h3>
                        </small>
                        <div
                          className="description-mobile-1"
                          dangerouslySetInnerHTML={{
                            __html: product.description,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div class="">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: expanded[product.id]
                            ? product.content
                            : truncateText(product.content, 1),
                        }}
                      ></div>
                      <button
                        className="btn btn-info btn-border"
                        onClick={() => toggleContent(product.id)}
                      >
                        {expanded[product.id] ? "Show Less" : "View More"}
                      </button>

                      <button
                        className="btn btn-info btn-border float-right"
                        onClick={() => data.addToCart(product)}
                      >
                        {data.isProductInCart(product.id) ? "Remove" : "Add"}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
      <>
        {categoryId !== '4' && (
          <>
            {!data.byPopularCategory && (
              <>
                <div className="">
                  <div className="container mt-2">
                    <PopularCategory
                      addToCart={data.addToCart}
                      isProductInCart={data.isProductInCart}
                    />
                  </div>
                </div>

                <div className="row align-items-end"></div>
                <div className="">
                  {data1 && data1.products[ToggleState].mini_services.length > 0 && (
                    <MiniSerices
                      addToCart={data.addToCart}
                      isProductInCart={data.isProductInCart}
                      selectedProduct={data1.products[ToggleState]}
                      handleIncrease={data.handleIncrease}
                      handleDecrease={data.handleDecrease}
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

export default Tab;

import React from "react";
import VideoModal from "./VideoModal";
import featureImage from "../img/feature.jpg";
import teamImage1 from "../img/team-1.jpg";
import teamImage2 from "../img/team-2.jpg";
import teamImage3 from "../img/team-3.jpg";
import teamImage4 from "../img/team-4.jpg";
import Footer from "./Footer";
import logoImage from "../img/logo-preview.png";
import Logo from "./Logo";
import Header from "./Header";

function About() {
  return (
    <>
      {/* Header Start */}
      <Header data={"about"} />
      {/* Header End  */}

      {/* Page Header Start  */}
      {/* <div className="container-fluid mb-5">
        <div className="bg-primary">
          <div className="row align-items-center py-4">
            <div className="col-md-6 text-center text-md-left">
              <h1 className="display-4 text-center mb-4 text-secondary-1 text-uppercase text-white">
                About Us
              </h1>
            </div>
            <div className="col-md-6 text-center text-md-left">
              <div className="d-inline-flex align-items-center">
                <a className="btn btn-sm btn-outline-light" href="/">
                  Home
                </a>
                <i className="fas fa-angle-double-right text-light mx-2"></i>
                <a
                  className="btn btn-sm btn-outline-light disabled"
                  href="/about"
                >
                  About Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Page Header End */}

      {/* About Start  */}
      <div className="container-fluid mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="d-flex flex-column align-items-center justify-content-center bg-about rounded h-100 py-5 px-3">
                <i className="fa fa-5x fa-award text-primary mb-4"></i>
                <h1 className="display-2 text-white mb-2">5</h1>
                <h2 className="text-white m-0">Years Experience</h2>
              </div>
            </div>
            <div className="col-lg-7 pb-lg-5">
              {/* <h6 className="text-secondary font-weight-semi-bold text-uppercase mb-3">
                Learn About Us
              </h6> */}
              <h1 className="mb-4 section-title">
                Affordable Monthly Packages
              </h1>
              <p>
                {/* <h5 className="text-muted font-weight-normal mb-3"> */}
                At SafaiXYZ.com, we understand the importance of cleanliness and
                hygiene in your daily life. That's why we have designed our
                monthly packages to be the most cost-effective in the market,
                ensuring that cleanliness is never a luxury but a necessity
                accessible to all.
                {/* </h5> */}
                {/* <p> */}
                Our packages are competitively priced, offering unparalleled
                value for money compared to other companies in the industry.
              </p>
              {/* <div className="d-flex align-items-center pt-4">
                <a href="" className="btn btn-primary mr-5">
                  Learn More
                </a>
                <button
                  type="button"
                  className="btn-play"
                  data-toggle="modal"
                  data-src="https://www.youtube.com/embed/DWRcNpR6Kdc"
                  data-target="#videoModal"
                >
                  <span></span>
                </button>
                <h5 className="font-weight-normal text-white m-0 ml-4 d-none d-sm-block">
                  Play Video
                </h5>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* About End  */}

      {/* Video Modal Start  */}
      <VideoModal />
      {/* Video Modal End */}

      {/* Features Start  */}
      <div className="container-fluid bg-multi-color py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-7 pt-lg-5 pb-3">
              {/* <h1 className="text-secondary font-weight-semi-bold text-uppercase mb-3">
                Why Choose Us
              </h1> */}
              <h1 className="mb-4 section-title">
                Deep Washroom Cleaning with Extra Benefits
              </h1>
              <p className="mb-4">
                Our flagship service includes deep washroom cleaning, where we
                go beyond the surface to ensure a spotless and germ-free
                environment. But that's not all. At SafaiXYZ.com, we believe in
                going the extra mile. With our deep washroom cleaning service,
                you can expect additional benefits such as fan cleaning and
                cobweb removal, ensuring every nook and corner of your washroom
                is thoroughly cleaned and sanitized.
              </p>
              <div className="row">
                <div className="col-sm-4">
                  <h1 className="text-secondary mb-2">225</h1>
                  <h6 className="font-weight-semi-bold mb-sm-4">
                    Our Cleaners
                  </h6>
                </div>
                <div className="col-sm-4">
                  <h1 className="text-secondary mb-2">1050</h1>
                  <h6 className="font-weight-semi-bold mb-sm-4">
                    Happy Clients
                  </h6>
                </div>
                <div className="col-sm-4">
                  <h1 className="text-secondary mb-2">2500</h1>
                  <h6 className="font-weight-semi-bold mb-sm-4">
                    Projects Done
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-5" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100 rounded overflow-hidden">
                <img
                  className="position-absolute w-100 h-100"
                  src={featureImage}
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features End  */}

      {/* Team Start  */}
      {/* <div className="container-fluid pt-5">
        <div className="container py-5">
          <div className="row align-items-end mb-4">
            <div className="col-lg-6">
              <h6 className="text-secondary font-weight-semi-bold text-uppercase mb-3">
                Meet Our Team
              </h6>
              <h1 className="section-title mb-3">
                Meet Our Highly Experienced Cleaners
              </h1>
            </div>
            <div className="col-lg-6">
              <h4 className="font-weight-normal text-muted mb-3">
                Eirmod kasd duo eos et magna, diam dolore stet sea clita sit ea
                erat lorem. Ipsum eos ipsum magna lorem stet
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="owl-carousel about-team-carousel position-relative">
                <div className="team d-flex flex-column text-center rounded overflow-hidden">
                  <div className="position-relative">
                    <div className="team-img">
                      <img
                        className="img-fluid w-100"
                        src={teamImage1}
                        alt=""
                      />
                    </div>
                    <div className="team-social d-flex flex-column align-items-center justify-content-center bg-primary">
                      <a className="btn btn-secondary btn-social mb-2" href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a className="btn btn-secondary btn-social mb-2" href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a className="btn btn-secondary btn-social" href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                  <div className="d-flex flex-column bg-primary text-center py-4">
                    <h5 className="font-weight-bold">Full Name</h5>
                    <p className="text-white m-0">Designation</p>
                  </div>
                </div>
                <div className="team d-flex flex-column text-center rounded overflow-hidden">
                  <div className="position-relative">
                    <div className="team-img">
                      <img
                        className="img-fluid w-100"
                        src={teamImage2}
                        alt=""
                      />
                    </div>
                    <div className="team-social d-flex flex-column align-items-center justify-content-center bg-primary">
                      <a className="btn btn-secondary btn-social mb-2" href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a className="btn btn-secondary btn-social mb-2" href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a className="btn btn-secondary btn-social" href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                  <div className="d-flex flex-column bg-primary text-center py-4">
                    <h5 className="font-weight-bold">Full Name</h5>
                    <p className="text-white m-0">Designation</p>
                  </div>
                </div>
                <div className="team d-flex flex-column text-center rounded overflow-hidden">
                  <div className="position-relative">
                    <div className="team-img">
                      <img
                        className="img-fluid w-100"
                        src={teamImage3}
                        alt=""
                      />
                    </div>
                    <div className="team-social d-flex flex-column align-items-center justify-content-center bg-primary">
                      <a className="btn btn-secondary btn-social mb-2" href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a className="btn btn-secondary btn-social mb-2" href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a className="btn btn-secondary btn-social" href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                  <div className="d-flex flex-column bg-primary text-center py-4">
                    <h5 className="font-weight-bold">Full Name</h5>
                    <p className="text-white m-0">Designation</p>
                  </div>
                </div>
                <div className="team d-flex flex-column text-center rounded overflow-hidden">
                  <div className="position-relative">
                    <div className="team-img">
                      <img
                        className="img-fluid w-100"
                        src={teamImage4}
                        alt=""
                      />
                    </div>
                    <div className="team-social d-flex flex-column align-items-center justify-content-center bg-primary">
                      <a className="btn btn-secondary btn-social mb-2" href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a className="btn btn-secondary btn-social mb-2" href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a className="btn btn-secondary btn-social" href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </div>
                  </div>
                  <div className="d-flex flex-column bg-primary text-center py-4">
                    <h5 className="font-weight-bold">Full Name</h5>
                    <p className="text-white m-0">Designation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Team End  */}
      <Footer />
    </>
  );
}

export default About;
